export default {
  passwordRequirement: 'dt.components.shared.auth.passwordRequirement',
  passwordRequirementCheckIcon: 'dt.components.shared.auth.passwordRequirementCheckIcon',
  passwordRequirementXIcon: 'dt.components.shared.auth.passwordRequirementXIcon',
  passwordError: 'dt.components.shared.auth.passwordError',
  passwordInput: 'dt.components.shared.auth.passwordInput',
  signIn: {
    emailInput: 'dt.components.shared.auth.signIn.emailInput',
    emailInputError: 'dt.components.shared.auth.signIn.emailInputError',
    forgotPasswordLink: 'dt.components.shared.auth.signIn.forgotPasswordLink',
    form: 'dt.components.shared.auth.signIn.form',
    passwordInput: 'dt.components.shared.auth.signIn.passwordInput',
    passwordInputError: 'dt.components.shared.auth.signIn.passwordInputError',
    signUpLink: 'dt.components.shared.auth.signIn.signUpLink',
    submitButton: 'dt.components.shared.auth.signIn.submitButton',
    signInViaRypButton: 'dt.components.shared.auth.signIn.signInViaRypButton',
  },
  signUp: {
    accordionTrigger: 'dt.components.shared.auth.signUp.accordionTrigger',
    emailError: 'dt.components.shared.auth.signUp.emailError',
    emailInput: 'dt.components.shared.auth.signUp.emailInput',
    form: 'dt.components.shared.auth.signUp.form',
    passwordError: 'dt.components.shared.auth.signUp.passwordError',
    passwordInput: 'dt.components.shared.auth.signUp.passwordInput',
    signInLink: 'dt.components.shared.auth.signUp.signInLink',
    submitButton: 'dt.components.shared.auth.signUp.submitButton',
    handleError: 'dt.components.shared.auth.signUp.handleError',
    handleInput: 'dt.components.shared.auth.signUp.handleInput',
    nameError: 'dt.components.shared.auth.signUp.nameError',
    nameInput: 'dt.components.shared.auth.signUp.nameInput',
    referralCodeError: 'dt.components.shared.auth.signUp.referralCodeError',
    referralCodeInput: 'dt.components.shared.auth.signUp.referralCodeInput',
    referralSuccessMessage: 'dt.components.shared.auth.signUp.referralSuccessMessage',
  },
  signUpVersionTwo: {
    signUpIntro: {
      continueOnWebButton:
        'dt.components.shared.auth.signUpVersionTwo.signUpIntro.continueOnWebButton',
    },
    emailForm: {
      continueButton: 'dt.components.shared.auth.signUpVersionTwo.firstStep.continueButton',
    },
    passwordForm: {
      continueButton: 'dt.components.shared.auth.signUpVersionTwo.passwordForm.continueButton',
      progressBar: 'dt.components.shared.auth.signUpVersionTwo.passwordForm.progressBar',
    },
    handleForm: {
      errorMessage: 'dt.components.shared.auth.signUpVersionTwo.handleForm.errorMessage',
      inputField: 'dt.components.shared.auth.signUpVersionTwo.handleForm.inputField',
      continueButton: 'dt.components.shared.auth.signUpVersionTwo.handleForm.continueButton',
    },
    nameForm: {
      firstName: 'dt.components.shared.auth.signUpVersionTwo.nameForm.firstName',
      lastName: 'dt.components.shared.auth.signUpVersionTwo.nameForm.lastName',
      monthField: 'dt.components.shared.auth.signUpVersionTwo.nameForm.monthField',
      dateField: 'dt.components.shared.auth.signUpVersionTwo.nameForm.dateField',
      yearField: 'dt.components.shared.auth.signUpVersionTwo.nameForm.yearField',
      continueButton: 'dt.components.shared.auth.signUpVersionTwo.nameForm.continueButton',
    },
    addressForm: {
      countryInputField: 'dt.components.shared.auth.signUpVersionTwo.addressForm.countryInputField',
      addressInputField: 'dt.components.shared.auth.signUpVersionTwo.addressForm.addressInputField',
      cityInputField: 'dt.components.shared.auth.signUpVersionTwo.addressForm.cityInputField',
      stateInputField: 'dt.components.shared.auth.signUpVersionTwo.addressForm.stateInputField',
      zipCodeInputField: 'dt.components.shared.auth.signUpVersionTwo.addressForm.zipCodeInputField',
      phoneNumberInputField:
        'dt.components.shared.auth.signUpVersionTwo.addressForm.phoneNumberInputField',
      continueButton: 'dt.components.shared.auth.signUpVersionTwo.addressForm.continueButton',
    },
    confiramtionModal: {
      confiramtionModal:
        'dt.components.shared.auth.signUpVersionTwo.confiramtionModal.confiramtionModal',
      title: 'dt.components.shared.auth.signUpVersionTwo.confiramtionModal.title',
      description: 'dt.components.shared.auth.signUpVersionTwo.confiramtionModal.description',
      confirmButton: 'dt.components.shared.auth.signUpVersionTwo.confiramtionModal.confirmButton',
      goBackButton: 'dt.components.shared.auth.signUpVersionTwo.confiramtionModal.goBackButton',
    },
  },
};
