export default {
  table: {
    filter: {
      sport: {
        badge: 'dt.lobby.table.filter.sport.badge',
        container: 'dt.lobby.table.filter.sport.container',
      },
    },
  },
};
