export default {
  components: {
    contestCard: 'dt.common.components.contestCard',
    manageInvites: 'dt.common.components.manageInvites',
    appShellHeader: {
      handle: 'dt.common.components.appShellHeader.handle',
      balance: 'dt.common.components.appShellHeader.balance',
      mobileMenuButton: 'dt.common.components.appShellHeader.mobileMenuButton',
      userMenuButton: 'dt.common.components.appShellHeader.userMenuButton',
      menuItem: 'dt.common.components.appShellHeader.menuItem',
      depositButton: 'dt.common.components.appShellHeader.depositButton',
      notificationsCounter: 'dt.common.components.appShellHeader.notificationsCounter',
      notificationsButton: 'dt.common.components.appShellHeader.notificationsButton',
      notificationsListItem: 'dt.common.components.appShellHeader.notificationsListItem',
      homeButton: 'dt.common.components.appShellHeader.homeButton',
      headerUserInfo: {
        handle: 'dt.common.components.appShellHeader.headerUserInfo.handle',
      },
    },
    appShellSubheader: {
      subHeaderLink: 'dt.common.components.appShellSubheader.subHeaderLink',
    },
    modal: {
      confirmModal: {
        confirmButton: 'dt.common.components.modal.confirmModal.confirmButton',
        cancelButton: 'dt.common.components.modal.confirmModal.cancelButton',
      },
    },
    banner: {
      generic: 'dt.common.components.banner.generic',
      verificationBanner: 'dt.common.components.banner.verificationBanner',
      RAFbanner: 'dt.common.components.banner.RAFbanner',
    },
    appDownloadPrompt: {
      continueUsingWeb: 'dt.common.components.appDownloadPrompt.continueUsingWeb',
    },
    RAFdrawer: {
      copyReferralLinkButton: 'dt.common.components.RAFdrawer.copyReferralLinkButton',
      copyCodeIcon: 'dt.common.components.RAFdrawer.copyCodeIcon',
    },
  },
};
