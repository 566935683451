export function IconCITI({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#D1D1D1" />
      <g clipPath="url(#clip0_147_1927)">
        <path
          d="M11.7651 19.2167L11.7252 19.2554C11.0985 19.8939 10.3733 20.2313 9.62627 20.2313C8.06612 20.2313 6.93436 19.0609 6.93436 17.4465C6.93436 15.8345 8.06612 14.6623 9.62627 14.6623C10.3733 14.6623 11.0985 15.0015 11.7252 15.6425L11.7651 15.6805L12.7684 14.4671L12.7416 14.4347C11.9073 13.4488 10.9058 12.9693 9.67491 12.9693C8.44088 12.9693 7.31099 13.3852 6.49787 14.1341C5.61241 14.946 5.14661 16.0908 5.14661 17.4465C5.14661 18.8021 5.61241 19.9494 6.49787 20.7613C7.31099 21.5146 8.44088 21.9274 9.67491 21.9274C10.9058 21.9274 11.9073 21.4472 12.7416 20.4608L12.7684 20.4308L11.7651 19.2167Z"
          fill="#004685"
        />
        <path d="M13.7817 21.7618H15.5539V13.1092H13.7817V21.7618Z" fill="#004685" />
        <path
          d="M22.4969 19.8808C22.0236 20.1689 21.584 20.3142 21.1887 20.3142C20.6156 20.3142 20.3562 20.0112 20.3562 19.3377V14.7464H22.1627V13.1177H20.3562V10.4252L18.6196 11.3555V13.1177H17.1205V14.7464H18.6196V19.6302C18.6196 20.9602 19.4078 21.8706 20.5851 21.8943C21.3838 21.9099 21.8652 21.6717 22.1571 21.4971L22.1758 21.4853L22.6017 19.8166L22.4969 19.8808Z"
          fill="#004685"
        />
        <path d="M23.964 21.7618H25.7387V13.1092H23.964V21.7618Z" fill="#004685" />
        <path
          d="M26.7722 11.5926C25.1528 9.29914 22.464 7.93104 19.7284 7.93104C16.9935 7.93104 14.3041 9.29914 12.6878 11.5926L12.6042 11.7104H14.6433L14.6657 11.6861C16.0532 10.2532 17.8652 9.49618 19.7284 9.49618C21.5916 9.49618 23.4025 10.2532 24.793 11.6861L24.8155 11.7104H26.8533L26.7722 11.5926Z"
          fill="#EE1C25"
        />
      </g>
      <defs>
        <clipPath id="clip0_147_1927">
          <rect width="22" height="14.2896" fill="white" transform="translate(5 7.78436)" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconCITI.displayName = 'IconCITI';
