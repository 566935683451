import { useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';

import log, { logError } from '~/common/utils/log';
import { createFastLinkUrl } from '~/services/wallet/wallet.service.api';

import { AEROPAY_WIDGET_ID } from '../../constants';
import { useAeropayLinkedBankAccounts } from '../../hooks/useAeropayLinkedBankAccounts';

import { useCreateAeropayDeposit } from './useCreateAeropayDeposit';
import { useCreateBankAccountLink } from './useCreateBankAccountLink';

export function useDepositWithAeropay({
  setCompletedDepositAmount,
}: {
  setCompletedDepositAmount?: (amount: number) => void;
}) {
  const theme = useMantineTheme();

  const { t } = useTranslation('payments');
  const { refetchBankAccounts } = useAeropayLinkedBankAccounts({ enabled: true });

  const createBankAccountLinkMutation = useCreateBankAccountLink();
  const createAeropayDepositMutation = useCreateAeropayDeposit({ setCompletedDepositAmount });

  return useMutation({
    mutationFn: async ({
      depositAmountWithFees,
      bankAccountId,
    }: {
      depositAmountWithFees: number;
      bankAccountId?: string;
    }) => {
      // if bank account id is provided, we don't need to create a fastlink url
      if (bankAccountId) {
        return createAeropayDepositMutation.mutateAsync({
          amount: depositAmountWithFees,
          bankAccountId,
        });
      }

      const { token } = await createFastLinkUrl();

      // we need to import this dynamically because there is no window check in the library
      const { openWidget } = await import('aerosync-web-sdk');

      return new Promise<void>((resolve, reject) => {
        const widgetRef = openWidget({
          id: AEROPAY_WIDGET_ID,
          iframeTitle: 'Connect',
          environment: process.env.NEXT_PUBLIC_ENV === 'production' ? 'production' : 'staging', // currently we only have production or staging available
          consumerId: process.env.NEXT_PUBLIC_AEROPAY_CONSUMER_ID,
          token,
          zIndex: `${theme.other.zIndex.aeropayWidget}`,
          style: {
            width: '420px',
            height: '734px',
            bgColor: '#000000',
            opacity: 0.6,
          },

          // This method will be triggered when a bank is added successfully and the user clicks on "continue" button in the final AeroSync-UI page.
          onSuccess: async (event: { user_id: string; user_password: string }) => {
            log('Aeropay: onSuccess');
            try {
              const amount = depositAmountWithFees;

              const data = await createBankAccountLinkMutation.mutateAsync({
                userId: event.user_id,
                userPassword: event.user_password,
              });

              await createAeropayDepositMutation.mutateAsync({
                amount,
                bankAccountId: data.bankAccountId,
              });

              refetchBankAccounts();
              resolve();
            } catch (error) {
              reject(error);
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (error: any) => {
            logError(error);
            showNotification({
              title: t('deposits.errors.aeropay.title'),
              message: error.message || t('deposits.errors.aeropay.general'),
              color: 'red',
              autoClose: 5000,
            });
            reject(new Error(error.message));
          },
          onClose: () => {
            log('Aeropay: onClose');
            reject(new Error('Aeropay: onClose'));
          },
          onLoad(): void {
            log('Aeropay: onLoad');
          },
          onEvent(event: object, type: string): void {
            log('Aeropay: onEvent', event, type);
          },
        });
        widgetRef.launch();
      });
    },
  });
}
