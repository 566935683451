import type { ContestType } from '@betterpool/api-types/contests-service';
import { Group, Loader, Paper, Stack } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

import { logError } from '~/common/utils/log';
import useIsUserAuthorised from '~/domains/auth/hooks/useIsUserAuthorised';
import { LinkNew } from '~/domains/common/components/Link/Link';
import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';
import { Title } from '~/domains/design-system/Title';
import dt from '~/testing';
import XPointSingleton from '~/utils/xpoint';

import { IconMapPinX } from '@tabler/icons-react';
import useStyles from './useStyles';

export const enum ContestTypeEnhanced {
  QUICK_PICKS = 'quick_picks',
}

type LocationInfoProps = {
  contestTypes: (ContestType | ContestTypeEnhanced)[];
  isInLobby?: boolean;
  locationState?: string;
  refreshQueryKeys?: string[][];
  showAsAlert?: boolean;
};

function LocationInfo({
  contestTypes,
  isInLobby,
  locationState,
  refreshQueryKeys,
  showAsAlert,
}: LocationInfoProps) {
  const [isLocationLoading, setLocationLoading] = useState(false);
  const isError = !!locationState && !showAsAlert;
  const { classes, theme } = useStyles({
    isError,
  });
  const { t } = useTranslation('contest');
  const queryClient = useQueryClient();
  const isUserAuthorised = useIsUserAuthorised();

  const handleLocationRecheck = async () => {
    setLocationLoading(true);
    try {
      const { errors, token } = await XPointSingleton.getValidLocationToken(0, true);

      if (refreshQueryKeys.length && !errors && token) {
        await Promise.all(
          refreshQueryKeys.map(async (queryKey) => {
            await queryClient.refetchQueries(queryKey);
          })
        );
      }
    } catch (e) {
      logError('Error refreshing location token', e);
    } finally {
      setLocationLoading(false);
    }
  };

  const invalidContestTypes = contestTypes
    .map((contestType) => t(`detail.locationInfo.contestTypes.${contestType}`))
    .join(' & ');

  return (
    <Paper p={1} radius="md" withBorder className={classes.paper}>
      <Group data-test-id={dt.contestDetail.page.locationInfo.container} p={theme.other.spacing._8}>
        <IconMapPinX
          color={locationState ? theme.colors.red[7] : theme.colors.orange[7]}
          size={24}
        />

        <Title
          tag="h4"
          variant="subhead-medium"
          c={isError ? theme.colors.red[7] : theme.colors.orange[7]}
        >
          {locationState && !showAsAlert
            ? t('detail.locationInfo.titleKnown', { locationState })
            : t('detail.locationInfo.titleUknown')}
        </Title>
      </Group>

      <Paper radius="md" className={classes.content} p={theme.other.spacing._8}>
        <Stack>
          <Text variant="body-medium">
            {isInLobby ? (
              <Trans
                i18nKey="contest:detail.locationInfo.copyLobby"
                values={{ invalidContestTypes, locationState: `(${locationState})` }}
                components={[
                  <LinkNew
                    key="contest-availability"
                    href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                  />,
                ]}
              />
            ) : (
              <>
                <Trans
                  i18nKey="contest:detail.locationInfo.copyContestDetail"
                  values={{ invalidContestTypes, locationState: `(${locationState})` }}
                  components={[
                    <LinkNew
                      key="contest-availability"
                      href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                    />,
                  ]}
                />
                <br />
                <br />
                <strong>{t('detail.locationInfo.copyContestDetailAppend')}</strong>
              </>
            )}
          </Text>
          {isUserAuthorised && (
            <Button
              data-test-id={dt.contestDetail.page.locationInfo.recheckLocationButton}
              variant="secondary-stroke"
              size="medium"
              onClick={handleLocationRecheck}
              loading={isLocationLoading}
              className={classes.button}
            >
              {isLocationLoading ? (
                <Loader stroke={theme.black} size="sm" sx={{ verticalAlign: 'bottom' }} />
              ) : (
                t('detail.locationInfo.checkLocationButtonLabel')
              )}
            </Button>
          )}
        </Stack>
      </Paper>
    </Paper>
  );
}

export default LocationInfo;
