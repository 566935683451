import { Radio, useMantineTheme } from '@mantine/core';
import type { ComponentProps } from 'react';

import dt from '~/testing';
import { Text } from '~/domains/design-system/Text';

type DepositOptionRadioProps = {
  isActive: boolean;
  onChange: ComponentProps<typeof Radio>['onChange'];
  children: React.ReactNode;
  value: string;
};

export function DepositOptionRadio({
  isActive,
  onChange,
  children,
  value,
  ...props
}: DepositOptionRadioProps) {
  const theme = useMantineTheme();

  return (
    <Radio
      name="depositOption"
      value={value}
      styles={{
        inner: {
          position: 'absolute',
          inset: 0,
          zIndex: -1,
          height: '100%',
        },
        radio: {
          width: '100%',
          height: '100%',
          borderRadius: 8,
          '&:checked': {
            background: theme.colors.gray2[5],
            borderColor: theme.colors.gray2[5],
          },
        },
        body: { width: '100%', height: '100%' },
        labelWrapper: { width: '100%', height: '100%' },
        icon: {
          display: 'none',
        },
        label: {
          paddingLeft: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        root: {
          position: 'relative',
          height: 48,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          ':has(input:checked) label': {
            color: theme.white,
          },
        },
      }}
      label={
        <Text data-test-id={dt.payments.deposits.depositForm.depositOption} variant="subhead-small">
          {children}
        </Text>
      }
      checked={isActive}
      onChange={onChange}
      {...props}
    />
  );
}
