import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  cls: {
    fill: '#0fddf0',
    strokeWidth: 0,
  },
}));

export function IconAeropay({ size = 32 }: { size?: number }) {
  const { classes } = useStyles();

  const width = size;
  const height = size * 0.6;

  return (
    <svg viewBox="0 0 210 150" width={width} height={height}>
      <path
        className={classes.cls}
        d="M48.61,91.92c6.12-3.28,109.21-61.38,111.83-62.69.87-.66,1.97-1.09,3.06-1.53,1.09-.44,2.18-.22,3.06.22.66.66,1.09,1.75.66,2.84-.44.87-1.09,1.75-1.97,2.18-1.31.87-109.43,68.37-114.24,71.42-3.06,1.97-5.9,4.15-6.12,8.08-.22,1.97.66,3.71,1.97,5.02,1.31,1.09,3.28,1.75,5.02,1.75s3.71-.22,5.46-.22c5.46,0,10.92,1.97,15.29,5.46,5.02,4.37,9.39,9.61,12.67,15.29,1.97,3.28,5.46,9.39,10.05,9.39h.22l1.53.22c3.93.22,7.21-3.28,9.61-6.55,10.27-13.32,97.64-129.96,101.35-135.86,1.31-1.97,1.09-5.9-2.62-6.33-2.62-.44-167.09,48.27-174.3,50.46l-8.52,2.84c-.66.22-1.53.44-2.18.87l-2.18.66c-1.53.44-3.06.87-4.37,1.53-3.28,1.09-6.55,2.4-9.61,3.71-.87.44-1.75,1.09-2.18,1.75-.66.87-.87,1.97-.87,3.06,0,2.18,1.31,4.15,3.49,5.02,3.06,1.31,6.33,2.62,9.61,3.49,8.96,3.28,16.82,8.74,22.5,16.16,1.09,1.53,1.97,2.84,4.15,3.06,1.53.22,3.06,0,4.59-.44l3.06-.87Z"
      />
    </svg>
  );
}
IconAeropay.displayName = 'IconAeropay';
