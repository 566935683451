import { PaymentType } from '~/common/enums/payments.enum';
import { CardType } from '~/services/wallet/interfaces/wallets.interface';

export const paymentTypeOptions = [
  PaymentType.AEROPAY,
  PaymentType.CARD,
  PaymentType.VIPPREFERRED,
  PaymentType.PAYPAL,
  PaymentType.PAPER_CHECK,
  PaymentType.VENMO,
  PaymentType.SKRILL,
];

export const cardTypeToTitle = {
  [CardType.VI]: 'Visa',
  [CardType.MC]: 'Mastercard',
  [CardType.AM]: 'American Express',
  [CardType.DI]: 'Discover',
};

export const AEROPAY_WIDGET_ID = 'aeropay-widget';

export const MAX_AEROPAY_ACCOUNTS = 5;
