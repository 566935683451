export default {
  common: {
    header: {
      backLink: 'dt.contest.gamecenter.common.header.backLink',
    },
    navigation: {
      nonScheduled: {
        standings: 'dt.contest.gamecenter.common.navigation.nonScheduled.standings',
        entries: 'dt.contest.gamecenter.common.navigation.nonScheduled.entries',
        stats: 'dt.contest.gamecenter.common.navigation.nonScheduled.stats',
        detail: 'dt.contest.gamecenter.common.navigation.nonScheduled.detail',
        availability: 'dt.contest.gamecenter.common.navigation.nonScheduled.availability',
      },
    },
    picks: {
      picksPanel: {
        picksMadeCount: 'dt.contest.gamecenter.common.picksPanel.picksMadeCount',
        pick: 'dt.contest.gamecenter.common.picksPanel.pick',
        container: 'dt.contest.gamecenter.common.picksPanel.container',
        submit: 'dt.contest.gamecenter.common.picksPanel.submit',
      },
      postPickModal: {
        container: 'dt.contest.gamecenter.common.picks.postPickModal.container',
        confetti: 'dt.contest.gamecenter.common.picks.postPickModal.confetti',
        title: 'dt.contest.gamecenter.common.picks.postPickModal.title',
        subtitle: 'dt.contest.gamecenter.common.picks.postPickModal.subtitle',
        pick: 'dt.contest.gamecenter.common.picks.postPickModal.pick',
        pickName: 'dt.contest.gamecenter.common.picks.postPickModal.pickName',
        actions: {
          newEntry: 'dt.contest.gamecenter.common.picks.postPickModal.actions.newEntry',
          viewMyEntries: 'dt.contest.gamecenter.common.picks.postPickModal.actions.viewMyEntries',
          continueEditing:
            'dt.contest.gamecenter.common.picks.postPickModal.actions.continueEditing',
        },
      },
      loadingOverlay: 'dt.contest.gamecenter.common.picks.loadingOverlay',
    },
    entriesScheduled: {
      entry: {
        container: 'dt.contest.gamecenter.common.entriesScheduled.entry.container',
        slate: 'dt.contest.gamecenter.common.entriesScheduled.entry.slate',
        moreActionsButton: 'dt.contest.gamecenter.common.entriesScheduled.entry.moreActionsButton',
        withdrawButton: 'dt.contest.gamecenter.common.entriesScheduled.entry.withdrawButton',
      },
    },
  },
  team: {
    picks: {
      sortOptionsSelect: 'dt.contest.gamecenter.team.picks.sortOptionsSelect',
    },
    game: {
      container: {
        SCHEDULED: 'dt.contest.gamecenter.team.game.container.scheduled',
        IN_PROGRESS: 'dt.contest.gamecenter.team.game.container.inProgress',
        FINALIZED: 'dt.contest.gamecenter.team.game.container.finalized',
        FINISHED: 'dt.contest.gamecenter.team.game.container.finished',
      },
      team: 'dt.contest.gamecenter.team.game.team',
      teamName: 'dt.contest.gamecenter.team.game.teamName',
      radioButton: 'dt.contest.gamecenter.team.game.radioButton',
      seed: 'dt.contest.gamecenter.team.game.seed',
      previewButton: 'dt.contest.gamecenter.team.game.previewButton',
    },
    gameInfo: {
      container: 'dt.contest.gamecenter.team.gameInfo.container',
    },
    entriesScheduled: {
      entry: {
        team: 'dt.contest.gamecenter.team.entriesScheduled.entry.team',
        teamAbbr: 'dt.contest.gamecenter.team.entriesScheduled.entry.teamAbbr',
        moreActionsButton: 'dt.contest.gamecenter.team.entriesScheduled.entry.moreActionsButton',
        withdrawButton: 'dt.contest.gamecenter.team.entriesScheduled.entry.withdrawButton',
      },
    },
    pickEm: {
      picksBar: {
        toggleExpand: 'dt.contest.gamecenter.team.pickEm.picksBar.toggleExpand',
        fullPick: {
          container: 'dt.contest.gamecenter.team.pickEm.picksBar.fullPick.container',
          teamName: 'dt.contest.gamecenter.team.pickEm.picksBar.fullPick.teamName',
          shuffleButton: 'dt.contest.gamecenter.team.pickEm.picksBar.fullPick.shuffleButton',
        },
      },
    },
  },
};
