/* eslint-disable no-template-curly-in-string */
export default {
  slip: {
    cancelled: 'Cancelled',
    voidedUnmatched: 'No match found',
    picks__hasPlural: {
      one: '1 pick',
      other: '{{count}} picks',
    },
    waitingForMatch: 'Waiting for match',
    partiallyMatched: 'Partially matched',
    winUpTo: 'Win up to',
    won: 'Won',
    refunded: 'Refunded',
    voided: 'Voided',
    splitSlipChildrenCount: 'Split Entry ({{count}})',
    splitSlipCopy: {
      contests__hasPlural: {
        one: 'This entry was split into {{count}} contest.',
        other: 'This entry was split into {{count}} contests.',
      },
      refunded: ' ${{amount}} was not matched & has been refunded.',
      remaining: ' remaining',
    },
  },
  slips: {
    empty: {
      active: {
        title: 'No active QuickSlips',
        subtitle: `
<p>
You do not have any active QuickPicks entries.
</p>

<p>
Tap on the Board tab to start making picks!
</p>
`,
      },
      final: {
        title: 'No final QuickPicks',
        subtitle: `
<p>
You do not have any final QuickPicks entries.
</p>

<p>
Tap on the Board tab to start making picks!
</p>
`,
      },
    },
  },
  slipDetail: {
    nav: {
      standings: 'Standings',
      detail: 'Details',
    },
    lookingForMatchBanner: {
      title: 'Looking for match...',
      subtitle: 'Your opponents and their picks will show when matched.',
    },
    userCancelledBanner: {
      title: 'Entry refunded',
      subtitle: 'You cancelled this entry. We have refunded your entry fee.',
    },
    voidedUnmatchedBanner: {
      title: 'Entry refunded',
      subtitle:
        'We were unable to match your entry into a contest and have refunded your entry fee.',
    },
    voidedByCSBanner: {
      title: 'Entry refunded',
      subtitle:
        'Your entry has been refunded. The full entry fee has been credited back to your account.',
    },
    contestResizedBanner: {
      title: 'Contest resized!',
      subtitle__hasPlural: {
        one: 'It’s your lucky day! To expedite matching, we’ve added {{count}} free slot to your contest giving you a better chance to win. Fewer opponents but the same guaranteed prizes.',
        other:
          'It’s your lucky day! To expedite matching, we’ve added {{count}} free slots to your contest giving you a better chance to win. Fewer opponents but the same guaranteed prizes.',
      },
    },
    splitEntryBanner: {
      title: 'Split Entry',
      subtitle__hasPlural: {
        one: 'Your entry has been split into <strong>1 contest</strong>. ',
        other: 'Your entry has been split into <strong>{{count}} contests</strong>. ',
      },
      remainingAmount:
        'There is still <strong>${{amount}} remaining</strong> of your original entry that is waiting to be matched.',
      refundedAmount:
        '<strong>${{amount}} of your original entry</strong> was not matched and has been refunded.',
    },
    detail: {
      entryFee: 'Entry fee',
      prize: 'Prizes',
      scoring: {
        push: 'Tie',
        correct: 'Correct',
        incorrect: 'Incorrect',
        dnp: 'DNP',
      },
      points__hasPlural: {
        one: '{{count}} point',
        other: '{{count}} points',
      },
      sections: {
        prize: {
          title: 'Prize payouts',
          resizingMode: {
            guaranteed: 'Guaranteed',
            guaranteedProp:
              'Once matched into a contest, the payout amounts will not change if the contest doesn’t fill.',
          },
        },
        detail: 'Details',
        rules: 'Scoring & Rules',
        entrants: {
          title: 'Entrants',
          waiting: 'Your opponents will show when matched.',
          error: 'There was an error loading the entrants.',
          noData: 'No entrants found.',
          retry: 'Retry',
        },
      },
    },
    entry: {
      title: 'Entry details',
      boostedWinnings: '{{amount}} + {{boostAmount}} in boosted winnings',
      noData: {
        title: 'Not found',
        description:
          'The entry you are looking for does not exist. Please check the URL and try again.',
      },
      prop: {
        game: {
          status: {
            live: 'Live',
            final: 'Final',
          },
        },
        status: {
          dnp: 'DNP',
        },
      },
    },
    dropdown: {
      cancelSlip: 'Cancel entry',
    },
    modal: {
      slipCancellation: {
        title: 'Cancel entry within {{remainingTime}}',
        description:
          'Do you want to cancel your entry? The entry fee will refunded and returned to your balance.',
        cancel: 'Not now',
        submit: 'Confirm',
        error: 'An error occurred while canceling the entry. Please try again later.',
        success: 'The entry has been successfully canceled.',
      },
    },
  },
  tabs: {
    board: 'Board',
    active: 'Active',
    final: 'Final',
  },
  board: {
    picksPanel: {
      viewEntry: 'View entry',
      winUpTo: 'Win up to {{payoutMultiplier}}x',
      minPicksRequired: '{{count}} picks required',
      addAtLeastAmountOfPicks: 'Add at least {{count}} picks',
    },
    picksDrawer: {
      errors: {
        minPicks: 'You must select at least {{count}} picks',
        atLeastTwoTeam: 'Your picks must include players from at least 2 teams.',
        invalidSlipLengthPromoApplied: 'Boost must be used on a {{count}} pick entry',
        invalidBetOptionPromoApplied: 'Boost must be used on a {{amount}} entry',
        apiError: {
          title: 'Submission failed',
        },
        locationError:
          'Based on your current location, you are not currently allowed to enter this contest.',
        kycError: 'Please verify your information before entering a contest.',
        entryLimitReached: {
          title: 'Entry limit reached',
          message:
            'You have hit the entry limit for QuickPicks entries. Please try submitting again later.',
          close: 'Ok',
        },
      },
      close: 'Close',
      title: 'Review picks: {{count}}',
      actionsPanel: {
        selectEntryFee: 'Select an entry fee',
        winUpTo: 'Win up to:',
        maxPayout: 'Max payout',
        splitEntryEligible: 'Split Entry eligible',
        footNote:
          'QuickPicks is a peer to peer contest. You’ll be entered into a winner-take-all contest. If there are ties, the winners will split the prizes.',
        footNoteInfoTooltip:
          'This is the guaranteed prize awarded to the entrant who scores the most points in your contest. If multiple entrants in your contest tie this prize will be split evenly across all winners.',
        footNoteRulesLink: 'View all rules',
        balance: 'Balance:',
        balanceError: 'There was an error while loading your account balance',
        submitLabel: {
          signInToEnter: 'Sign in to submit entry',
          default: 'Submit entry',
          submitting: 'Submitting...',
          balanceError: 'Balance error',
          balanceInsufficient: 'Deposit to submit entry',
          kycInvalid: 'Verify to submit entry',
          locationInvalid: 'Invalid location',
        },
      },
    },
    entryChangedAlert: {
      title: 'Your selections have changed',
      text: 'The projected stat total for the following players has changed or been removed. After accepting, you can view your updated selections.',
      submit: 'Accept & view my picks',
      removed: 'Removed',
    },
    entrySubmitted: {
      title: 'Entry submitted!',
      subtitle:
        'Your entry has been submitted. It will be matched into a {{groupSize}}-entry, winner-take-all contest.',
      picksCount: '{{count}} picks',
      winUpTo: 'Win up to:',
      picksReuse: 'Reuse picks',
      close: 'Close',
      boostApplied: '{{selectedBoost}} applied!',
    },
    playerCard: {
      more: 'More',
      less: 'Less',
      homeVsAway: '<strong>{{homeTeam}}</strong> vs {{awayTeam}}',
      awayAtHome: '<strong>{{awayTeam}}</strong> @ {{homeTeam}}',
    },
    filters: {
      gameModal: {
        title: 'Filter by game',
        allGames: 'All games',
        submit: 'Show Picks',
      },
      searchInputPlaceholder: 'Search for players or teams',
    },
    maxPicksReachedError: {
      title: 'Max picks reached',
      message: 'Max of {{count}} picks allowed. Remove a pick to add another.',
    },
    emptyScreen: {
      noProps: {
        title: 'No picks available yet',
        subtitle: 'Choose another sport or try again later.',
      },
      noSearchResults: {
        title: 'No results found',
        subtitle: 'Try another search.',
      },
    },
    errors: {
      maxEntriesReached: 'Max of {{count}} picks allowed. Remove a pick to add another.',
      samePlayer: 'Entries can not include the same player more than once.',
      notAllowedLocation: 'You might be in a restricted location.',
    },
  },
  playerCard: {
    otherPicks: 'Other picks',
    picks: 'Picks',
    gameLog: 'Game Log',
    noOtherPicks: 'No other picks',
    error: {
      title: 'Error',
      message: 'There was an error loading this player card. Please refresh and try again.',
      cta: 'Try again',
    },
    lastXAvg: 'Last {{count}} avg: ',
    lastXAvgShort: 'L{{count}} avg: ',
  },
  freeSlot: {
    title: 'Free Slot',
    // eslint-disable-next-line no-template-curly-in-string
    amount: '+${{amount}}',
  },
  boost: {
    title: 'Boost',
    viewTerms: 'View terms',
    expires: 'Expires:',
    terms: 'Promotion Terms',
    close: 'Close',
    xBoostsAvailable__hasPlural: {
      one: 'You have 1 promotion available',
      other: 'You have {{count}} promotions available',
    },
    applyPromo: 'Apply promo',
    removePromo: 'Remove promo',
    remove: 'Remove',
  },
};
