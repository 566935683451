export default {
  enterContestModal: {
    modal: 'dt.contest.common.enterContestModal.modal',
    title: 'dt.contest.common.enterContestModal.title',
    action: {
      decrement: 'dt.contest.common.enterContestModal.action.decrement',
      increment: 'dt.contest.common.enterContestModal.action.increment',
      numberOfEntries: 'dt.contest.common.enterContestModal.action.numberOfEntries',
      createEntries: 'dt.contest.common.enterContestModal.action.createEntries',
      depositToEnter: 'dt.contest.common.enterContestModal.action.depositToEnter',
      cancel: 'dt.contest.common.enterContestModal.cancel',
    },
  },
};
