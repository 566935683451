import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { getBankAccounts } from '~/services/wallet/wallet.service.api';
import { UserContext } from '~/components/providers/UserProvider';

import { PaymentsQueryKeys } from '../query';

import { PAYMENTS_QUERY_STALE_TIME } from './consts';

type UseAeropayLinkedBankAccountsProps = {
  enabled?: boolean;
};

export function useAeropayLinkedBankAccounts({
  enabled = false,
}: UseAeropayLinkedBankAccountsProps) {
  const { user } = useContext(UserContext);

  const { data, isLoading, refetch } = useQuery({
    queryKey: PaymentsQueryKeys.GET_AEROPAY_LINKED_BANK_ACCOUNTS(user?.id),
    queryFn: getBankAccounts,
    staleTime: PAYMENTS_QUERY_STALE_TIME,
    enabled,
  });

  return {
    bankAccounts: data?.bankAccounts,
    isLoading,
    refetchBankAccounts: refetch,
  };
}
