export default {
  components: {
    paymentType: 'dt.payments.withdrawal.components.paymentType',
    bankAccount: 'dt.payments.withdrawal.components.bankAccount',
    deleteBankAccountButton: 'dt.payments.withdrawal.components.deleteBankAccountButton',
  },
  paymentTypeForm: {
    continueButton: 'dt.payments.withdrawal.paymentTypeForm.continueButton',
  },
  withdrawalForm: {
    cardSelect: 'dt.payments.withdrawal.withdrawalForm.cardSelect',
    amountInput: 'dt.payments.withdrawal.withdrawalForm.amountInput',
    summary: {
      serviceFee: 'dt.payments.withdrawal.withdrawalForm.summary.serviceFee',
      transferAmount: 'dt.payments.withdrawal.withdrawalForm.summary.transferAmount',
      remainingBalance: 'dt.payments.withdrawal.withdrawalForm.summary.remainingBalance',
      withdrawalAmount: 'dt.payments.withdrawal.withdrawalForm.summary.withdrawalAmount',
    },
  },
  withdrawButton: 'dt.payments.withdrawal.withdrawButton',
  withdrawButtonTooltip: 'dt.payments.withdrawal.withdrawButtonTooltip',
  withdrawnAmountText: 'dt.payments.withdrawal.withdrawnAmountText',
  remainingBalanceText: 'dt.payments.withdrawal.remainingBalanceText',
  continueButton: 'dt.payments.withdrawal.continueButton',
};
