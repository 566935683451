export default {
  components: {
    kyc: {
      status: {
        continueButton: 'dt.domains.kyc.components.kyc.status.continueButton',
      },
    },
  },
  form: {
    kyc: {
      stepOne: {
        firstNameInput: 'dt.domains.kyc.form.kyc.stepOne.firstNameInput',
        lastNameInput: 'dt.domains.kyc.form.kyc.stepOne.lastNameInput',
      },
      dateOfBirth: {
        month: 'dt.domains.kyc.form.kyc.dateOfBirth.month',
        day: 'dt.domains.kyc.form.kyc.dateOfBirth.day',
        year: 'dt.domains.kyc.form.kyc.dateOfBirth.year',
      },
      actions: {
        continue: 'dt.domains.kyc.form.kyc.actions.continue',
      },
      stepTwo: {
        addressMainInput: 'dt.domains.kyc.form.kyc.stepTwo.addressMainInput',
        addressOptionalInput: 'dt.domains.kyc.form.kyc.stepTwo.addressOptionalInput',
        cityInput: 'dt.domains.kyc.form.kyc.stepTwo.cityInput',
        stateInput: 'dt.domains.kyc.form.kyc.stepTwo.stateInput',
        zipCodeInput: 'dt.domains.kyc.form.kyc.stepTwo.zipCodeInput',
        countrySelect: 'dt.domains.kyc.form.kyc.stepTwo.countrySelect',
        mobileNumberInput: 'dt.domains.kyc.form.kyc.stepTwo.mobileNumberInput',
      },
    },
  },
};
