import type { Branch } from 'branch-sdk';
import Cookies from 'js-cookie';

import log, { logError } from '~/common/utils/log';
import { isBrowserFn } from '~/utils/browser';

class BranchService {
  private static instance: Branch;

  private static activeUserId: string;

  private static isInitialized = false;

  private static async initBranch() {
    if (!process.env.NEXT_PUBLIC_BRANCH_KEY) {
      logError('Branch: No key found! Skipping initialisation');
      return;
    }

    if (this.isInitialized) return this.instance;

    try {
      const branchInstance = window.branch;

      if (!branchInstance) {
        logError('Branch.io init error', 'Branch instance not found');
        return;
      }

      const searchQuery = new URLSearchParams(window?.location?.search);
      const isWebView = Cookies.get('webView') === 'true' || searchQuery.get('webView') === 'true';

      // TODO: Rewrite all branch SDK functions to return promises
      return await new Promise((resolve, reject) => {
        branchInstance.init(
          process.env.NEXT_PUBLIC_BRANCH_KEY,
          {
            // Do not enable journeys on webview (used on native apps)
            no_journeys: isWebView,
            // DEBUG set this when testing journeys locally
            // branch_view_id: '1392123315283055619',
          },
          (err, data) => {
            if (err) {
              logError('Branch.io init error', err);
              reject(err);
              return;
            }

            log('Branch.io init success', data);
            this.isInitialized = true;
            this.instance = branchInstance;
            resolve(branchInstance);
          }
        );
      });
    } catch (error) {
      logError(error);
    }
  }

  private static async getInstance() {
    if (!this.instance && isBrowserFn()) {
      const instance = await this.initBranch();
      this.instance = instance;
    }

    return this.instance;
  }

  public static async start() {
    await this.getInstance();
    return true;
  }

  public static async setUserId(userId: string) {
    if (!userId || userId === this.activeUserId) return;
    const instance = await this.getInstance();

    this.activeUserId = userId;
    return instance?.setIdentity(userId, (err, data) => {
      if (err) {
        logError('Branch.io setIdentity error', err);
        return;
      }

      log('Branch.io setIdentity success', data);
    });
  }

  // FIY: Branch SDK will always throw an error when clearing the user ID, because it's a POS...
  public static async clearUserId() {
    const instance = await this.getInstance();
    this.activeUserId = null;

    try {
      instance?.logout();
    } catch (error) {
      logError('Branch.io clearUserId error', error);
    }
  }
}

export const isBranchInitialised = () => isBrowserFn() && !!window.branch;

export default BranchService;
