export default {
  components: {
    signUpHeader: {
      title: 'dt.auth.components.signUpHeader.title',
    },
  },
  forgotPassword: {
    emailInput: 'dt.auth.forgotPassword.emailInput',
    requestPasswordLinkButton: 'dt.auth.forgotPassword.requestPasswordLinkButton',
    passwordInput: 'dt.auth.forgotPassword.passwordInput',
    resetPasswordButton: 'dt.auth.forgotPassword.resetPasswordButton',
  },
};
