export default {
  detail: {
    general: {
      main: 'dt.quickPick.detail.general.main',
      filterButton: 'dt.quickPick.detail.general.filterButton',
      emptyState: 'dt.quickPick.detail.general.emptyState',
      subHeaderFilter: 'dt.quickPick.detail.general.subHeaderFilter',
      searchFilterInputField: 'dt.contest.quickPicks.detail.general.searchFilterInputField',
      emptyStateQuickPicks: 'dt.contest.quickPicks.detail.general.emptyStateQuickPicks',
      iconInfoButton: 'dt.contest.quickPicks.detail.iconInfoButton',
    },
    entries: {
      main: 'dt.quickPick.detail.entries.main',
    },
    prizePayout: {
      main: 'dt.quickPick.detail.prizePayout.main',
    },
    description: {
      main: 'dt.quickPick.detail.description.main',
    },
    rules: {
      main: 'dt.quickPick.detail.rules.main',
    },
    entrants: {
      main: 'dt.quickPick.detail.entrants.main',
    },
  },
  tutorial: {
    tutorialModal: 'dt.quickPick.tutorial.tutorialModal',
    closeButton: 'dt.quickPick.tutorial.closeButton',
    nextButton: 'dt.contest.quickPicks.tutorial.nextButton',
    placeholderIndicator: 'dt.contest.quickPicks.tutorial.placeholderIndicator',
  },
  playerCell: {
    playerCell: 'dt.quickPick.playerCell.playerCell',
    playerName: 'dt.quickPick.playerCell.playerName',
    passYdsNumber: 'dt.quickPick.playerCell.passYdsNumber',
    metaData: 'dt.quickPick.playerCell.metaData',
    playerPosition: 'dt.contest.quickPicks.playerCell.playerPosition',
    playerYards: 'dt.contest.quickPicks.playerCell.playerYards',
  },
  search: {
    boardSearchCloseButton: 'dt.contest.quickPicks.search.boardSearchCloseButton',
  },
};
