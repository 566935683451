import React, { useState, useEffect } from 'react';
import { createStyles, TextInput, Stack, Box, Group, Skeleton } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { SignUpHeader } from '~/domains/auth/components/SignUpHeader/SignUpHeader';

import { useAeropayChallenge, useAeropayConfirmUser } from '../hooks/useAeropayChallenge';

// Define types for the props
interface VerificationProps {
  setAeropayChallengeRequired: (verified: boolean) => void;
  setAeropayVerified: (verified: boolean) => void;
}

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.md,
    maxWidth: 400,
  },
  instructions: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7],
    marginBottom: theme.spacing.md,
  },
  pinInputContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing.xs,
  },
  pinInput: {
    width: 40,
    height: 50,
    fontSize: theme.fontSizes.md,
    textAlign: 'center',
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    outline: 'none',
    transition: 'border-color 0.3s',

    '&:focus': {
      borderColor: theme.colors.blue[6],
    },
  },
}));

function Verification({ setAeropayChallengeRequired, setAeropayVerified }: VerificationProps) {
  const { classes } = useStyles();
  const [pin, setPin] = useState<string[]>(Array(6).fill(''));

  const [displayMessage, setDisplayMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const { t } = useTranslation('payments');

  const handleInputChange = (value: string, index: number) => {
    if (/^\d$/.test(value) || value === '') {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      // Move to the next input field if valid digit is entered
      if (value && index < 5) {
        const nextInput = document.getElementById(`pin-${index + 1}`);
        if (nextInput) (nextInput as HTMLInputElement).focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'Backspace' && pin[index] === '' && index > 0) {
      const prevInput = document.getElementById(`pin-${index - 1}`);
      if (prevInput) {
        (prevInput as HTMLInputElement).focus();
      }
    }
  };

  const clearPin = () => {
    // Clear the pin array
    setPin(Array(6).fill(''));
    // Focus the first input box
    const firstInput = document.getElementById('pin-0');
    if (firstInput) {
      (firstInput as HTMLInputElement).focus();
      (firstInput as HTMLInputElement).select();
    }
  };

  const { mutate: checkChallengeRequired, isLoading } = useAeropayChallenge();
  const { mutate: confirmUser } = useAeropayConfirmUser({
    setIsDisabled,
    clearPin,
    setAeropayVerified,
  });

  useEffect(() => {
    checkChallengeRequired(undefined, {
      onSuccess: (response) => {
        setAeropayChallengeRequired(!!response.displayChallenge);

        if (response?.displayMessage) {
          setDisplayMessage(response.displayMessage);
        }
      },
    });
  }, [checkChallengeRequired, setDisplayMessage, setAeropayChallengeRequired]);

  useEffect(() => {
    const allFieldsFilled = pin.every((digit) => digit !== '');
    setIsDisabled(allFieldsFilled);

    if (allFieldsFilled) {
      confirmUser(pin.join(''));
    }
  }, [pin, confirmUser]);

  const showSkeleton = isLoading;

  return (
    <Skeleton visible={showSkeleton}>
      <Stack spacing={32}>
        <SignUpHeader
          title={t('deposits.aeropay.verify.title')}
          copy={displayMessage}
          isLogoVisible={false}
        />

        <Box className={classes.container}>
          <Group className={classes.pinInputContainer}>
            {pin.map((digit, index) => (
              <TextInput
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                id={`pin-${index}`}
                type="text"
                inputMode="numeric"
                maxLength={1}
                value={digit}
                onChange={(e) => handleInputChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                classNames={{ input: classes.pinInput }}
                disabled={isDisabled} // Disable input once all fields are filled
              />
            ))}
          </Group>
        </Box>
      </Stack>
    </Skeleton>
  );
}

export default Verification;
