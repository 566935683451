function IconChevronLeft({
  size = 24,
  color = '#1a1a1a',
  strokeWidth = 1.5,
}: {
  size?: number;
  color?: string;
  strokeWidth?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m15 6-6 6 6 6"
      />
    </svg>
  );
}

IconChevronLeft.displayName = 'IconChevronLeft';

export { IconChevronLeft };
