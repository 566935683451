export function IconUSAA({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#D1D1D1" />
      <g clipPath="url(#clip0_147_1867)">
        <path
          d="M26.7532 20.6921C26.1133 20.6921 25.6047 21.1679 25.6047 21.7585C25.6047 22.3492 26.1133 22.825 26.7532 22.825C27.393 22.825 27.9016 22.3492 27.9016 21.7585C27.918 21.1679 27.393 20.6921 26.7532 20.6921ZM26.7532 22.6281C26.2282 22.6281 25.8016 22.2343 25.8016 21.7585C25.8016 21.2664 26.2282 20.889 26.7532 20.889C27.2782 20.889 27.7047 21.2828 27.7047 21.7585C27.7047 22.2343 27.2782 22.6281 26.7532 22.6281ZM7.14771 20.8234V24.2523C7.14771 26.1718 8.34536 26.4835 9.34614 26.4835C10.8227 26.4835 11.5118 25.5156 11.5118 24.2195V20.8234H10.5274V24.3671C10.5274 24.8101 10.3797 25.6468 9.34614 25.6304C8.41099 25.614 8.23052 24.7609 8.23052 24.2195V20.8234H7.14771ZM15.5969 21.0531C15.5969 21.0531 14.9735 20.7578 14.268 20.7578C13.3821 20.7578 12.2665 21.2007 12.2665 22.4148C12.2665 23.2515 12.9063 23.6617 13.3329 23.8585C13.5625 23.9734 13.8415 24.0882 14.0711 24.2195C14.186 24.2851 14.6618 24.4328 14.6618 24.9414C14.6618 25.4828 14.0711 25.6632 13.7922 25.6632C12.8407 25.6632 12.1844 25.2367 12.1844 25.2367L12.2829 26.3031C12.2829 26.3031 12.6766 26.5 13.8415 26.5C14.9571 26.5 15.761 25.7945 15.761 24.8265C15.761 24.0882 15.2524 23.6453 14.8094 23.432C14.4649 23.2679 14.268 23.1695 13.9563 23.0382C13.661 22.8906 13.3493 22.6609 13.3493 22.2835C13.3493 21.8898 13.7266 21.5945 14.268 21.5945C15.0719 21.5945 15.4 21.9554 15.5969 22.0703V21.0531ZM23.554 20.8234H22.3891L22.5696 21.2828L20.7485 26.057L18.8618 20.8234H17.6969L17.8774 21.2828L15.9415 26.3687H17.0079L17.3524 25.4007H19.354L19.6985 26.3687H21.7L22.0446 25.4007H24.0461L24.3907 26.3687H25.5719L23.554 20.8234ZM17.5985 24.646L18.386 22.464L19.075 24.646H17.5985ZM22.2907 24.646L23.0618 22.464L23.7672 24.646H22.2907Z"
          fill="#1A3258"
        />
        <path
          d="M26.9828 21.7914C26.9828 21.7914 27.2125 21.7094 27.2125 21.5125C27.2125 21.2992 26.9992 21.1844 26.7367 21.1844C26.4742 21.1844 26.3266 21.25 26.3266 21.25V22.2672H26.6383V21.8898H26.7367L27.0648 22.2672H27.4586L26.9828 21.7914ZM26.7859 21.7094H26.6383V21.3648C26.6383 21.3648 26.8844 21.3484 26.8844 21.5125C26.9008 21.693 26.7859 21.7094 26.7859 21.7094ZM7.08203 14.5726L7.13125 16.3117C7.13125 16.3117 7.55781 16 8.05 16C8.54219 16 9.08359 16.3445 9.08359 16.3445L13.4313 18.8383L13.7922 17.2633L9.1 14.5726C9.1 14.5726 8.55859 14.2281 8.06641 14.2281C7.57422 14.2281 7.08203 14.589 7.08203 14.5726Z"
          fill="#1A3258"
        />
        <path
          d="M7.08203 12.3086L7.13125 14.0477C7.13125 14.0477 7.55781 13.736 8.05 13.736C8.54219 13.736 9.08359 14.0805 9.08359 14.0805L13.907 16.8531L14.268 15.2781L9.1 12.2922C9.1 12.2922 8.55859 11.9477 8.06641 11.9477C7.57422 11.9477 7.08203 12.3086 7.08203 12.3086Z"
          fill="#1A3258"
        />
        <path
          d="M7.08203 10.0445L7.13125 11.7836C7.13125 11.7836 7.55781 11.4719 8.05 11.4719C8.54219 11.4719 9.08359 11.8164 9.08359 11.8164L14.3828 14.8351L14.7438 13.2601L9.1 10.0281C9.1 10.0281 8.55859 9.68357 8.06641 9.68357C7.57422 9.68357 7.08203 10.0445 7.08203 10.0445Z"
          fill="#1A3258"
        />
        <path
          d="M16.1546 7.27188L16.6468 9.32266L23.275 13.1289C23.275 13.1289 23.7999 13.4734 24.3085 13.4734C24.8171 13.4734 25.2765 13.1125 25.2765 13.1125L25.2109 11.3734C25.2109 11.3734 24.8007 11.6852 24.2921 11.6852C23.7835 11.6852 23.2585 11.3406 23.2585 11.3406L18.1398 8.40391L17.7789 7.09141C17.7789 7.04219 17.8281 7.00938 17.8445 7.00938L18.714 6.74688C18.8289 6.74688 18.8945 6.84532 18.8945 6.92735L18.9437 7.02578C18.9765 7.04219 19.1078 6.97657 19.1078 6.97657V6.43516C19.1078 6.07422 18.8125 5.77891 18.4515 5.77891C18.4515 5.77891 18.4515 5.77891 18.4351 5.77891H17.5656C17.5656 5.77891 17.4343 5.4836 17.0734 5.4836H15.4328C15.0062 5.4836 14.8914 5.89375 14.8914 5.89375L13.7429 10.4383L9.09995 7.76407C9.09995 7.76407 8.59136 7.41953 8.06636 7.41953C7.54136 7.41953 7.09839 7.78047 7.09839 7.78047L7.14761 9.51953C7.14761 9.51953 7.54136 9.20782 8.06636 9.20782C8.59136 9.20782 9.09995 9.55235 9.09995 9.55235L14.8585 12.85L16.1546 7.27188Z"
          fill="#1A3258"
        />
        <path
          d="M16.7781 9.8969L17.2703 11.9477L23.275 15.393C23.275 15.393 23.8164 15.7375 24.3085 15.7375C24.8007 15.7375 25.2765 15.3766 25.2765 15.3766L25.2273 13.6375C25.2273 13.6375 24.85 13.9492 24.3085 13.9492C23.7671 13.9492 23.275 13.6047 23.275 13.6047L16.7781 9.8969Z"
          fill="#1A3258"
        />
        <path
          d="M17.3852 12.5055L17.8774 14.5562L23.275 17.6406C23.275 17.6406 23.8164 17.9851 24.3086 17.9851C24.8008 17.9851 25.2766 17.6242 25.2766 17.6242L25.211 15.918C25.211 15.918 24.8336 16.2297 24.2922 16.2297C23.7508 16.2297 23.2586 15.8851 23.2586 15.8851L17.3852 12.5055Z"
          fill="#1A3258"
        />
        <path
          d="M18.0085 15.1305L18.4843 17.1976L23.2585 19.9375C23.2585 19.9375 23.8 20.282 24.2921 20.282C24.7843 20.282 25.2601 19.9211 25.2601 19.9211L25.2109 18.182C25.2109 18.182 24.8335 18.4937 24.2921 18.4937C23.7507 18.4937 23.2585 18.1492 23.2585 18.1492L18.0085 15.1305Z"
          fill="#1A3258"
        />
      </g>
      <defs>
        <clipPath id="clip0_147_1867">
          <rect width="21" height="21" fill="white" transform="translate(7 5.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconUSAA.displayName = 'IconUSAA';
