import { Flex, createStyles, Box } from '@mantine/core';
import { IconBuildingBank } from '@tabler/icons-react';

import {
  IconAeropay,
  IconBankOfAmerica,
  IconCapitalOne,
  IconCharlesSchwab,
  IconChase,
  IconCITI,
  IconUSAA,
  IconWellsFargo,
} from '~/domains/payments/icons/BankIcons';
import { Title } from '~/domains/design-system/Title';
import { AeropayBankName } from '~/common/enums/payments.enum';

const useStyles = createStyles((theme) => ({
  container: {
    alignItems: 'center',
    gap: 4,
    maxWidth: 138,
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  title: {
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray2[1]}`,
  },
}));

export function PopularBankIcons() {
  const { classes, cx } = useStyles();
  return (
    <Flex className={classes.container}>
      <Box className={classes.iconBox}>
        <IconBankOfAmerica size={24} />
      </Box>
      <Box className={classes.iconBox}>
        <IconChase size={24} />
      </Box>
      <Box className={classes.iconBox}>
        <IconUSAA size={24} />
      </Box>
      <Box className={classes.iconBox}>
        <IconCITI size={24} />
      </Box>
      <Box w={24} h={24} className={cx(classes.iconBox, classes.title)}>
        <Title sx={{ width: 24 }} c="gray2.3" variant="subhead-x-small">
          •••
        </Title>
      </Box>
    </Flex>
  );
}

type BankIconProps = {
  bankName: string;
};

const bankNameIconComponent = {
  [AeropayBankName.AEROSYNC]: IconAeropay,
  [AeropayBankName.AEROSYNC_MFA]: IconAeropay,
  [AeropayBankName.AEROSYNC_MFA_TRIGGER]: IconAeropay,
  [AeropayBankName.AEROSYNC_OAUTH]: IconAeropay,
  [AeropayBankName.BANKOFAMERICA]: IconBankOfAmerica,
  [AeropayBankName.BANKOFAMERICA_OAUTH]: IconBankOfAmerica,
  [AeropayBankName.CAPITALONE]: IconCapitalOne,
  [AeropayBankName.CAPITALONE_INVESTMENTS]: IconCapitalOne,
  [AeropayBankName.CHARLESSCHWAB]: IconCharlesSchwab,
  [AeropayBankName.CHASE]: IconChase,
  [AeropayBankName.CITI]: IconCITI,
  [AeropayBankName.USAA]: IconUSAA,
  [AeropayBankName.WELLSFARGO]: IconWellsFargo,
};

export function BankIcon({ bankName }: BankIconProps) {
  const Icon = bankNameIconComponent[bankName] ?? IconBuildingBank;
  return <Icon />;
}
