import { useTheme } from '@emotion/react';
import { Group, Input, SimpleGrid, Skeleton, Stack, Text, Tooltip } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { PAYMENT_TYPE_TO_METHOD, PaymentType } from '~/common/enums/payments.enum';
import { snakeCaseToTitleCase, upperCaseToTitleCase } from '~/components/utils/formatters';
import { ErrorAlert } from '~/domains/common/components/Alerts/Alerts';
import { Title } from '~/domains/design-system/Title';
import { RadioButton, RadioButtonGroup } from '~/domains/payments/components/RadioButton';
import PaymentTypeIcon from '~/domains/payments/icons/PaymentTypeIcon';
import dt from '~/testing';

import { MAX_AEROPAY_ACCOUNTS } from '../../constants';
import { useAeropayLinkedBankAccounts } from '../../hooks/useAeropayLinkedBankAccounts';
import type { useAvailablePaymentMethods } from '../../hooks/useAvailablePaymentMethods';
import { PaymentTypeRadio } from '../../withdrawal/components/PaymentTypeRadio';

type DepositMethodsProps = {
  availablePaymentMethods: ReturnType<typeof useAvailablePaymentMethods>['availablePaymentMethods'];
  bankAccountId?: string;
  paymentType: PaymentType;
  filteredPaymentTypes: PaymentType[];
  isLoading: boolean;
  onChange: (type: PaymentType, bankAccountId?: string) => void;
  isNewDepositUIEnabled?: boolean;
  isAeropayEnabled?: boolean;
};

export function DepositMethods({
  availablePaymentMethods,
  bankAccountId,
  filteredPaymentTypes,
  isLoading,
  paymentType,
  onChange,
  isNewDepositUIEnabled,
  isAeropayEnabled,
}: DepositMethodsProps) {
  const { t } = useTranslation('payments');
  const theme = useTheme();

  const { bankAccounts, isLoading: isLoadingAeropayLinkedBankAccounts } =
    useAeropayLinkedBankAccounts({ enabled: isAeropayEnabled });

  const getIsPaymentTypeDisabled = (type: PaymentType) => {
    if (type === PaymentType.AEROPAY && bankAccounts?.length >= MAX_AEROPAY_ACCOUNTS) {
      return { isDisabled: true, disabledReason: t('deposits.errors.aeropay.maxAccounts') };
    }

    const isDisabled = availablePaymentMethods?.some(
      (item) => item.paymentMethod === PAYMENT_TYPE_TO_METHOD[type] && item.status === 'disabled'
    );

    return {
      isDisabled,
    };
  };
  const isCurrentPaymentTypeDisabled = getIsPaymentTypeDisabled(paymentType).isDisabled;

  const data = filteredPaymentTypes.map((type) => {
    const { isDisabled, disabledReason } = getIsPaymentTypeDisabled(type);

    return {
      type,
      settings: { isFree: true, description: '', isDisabled, disabledReason },
    };
  });

  if (isNewDepositUIEnabled) {
    return (
      <Stack spacing={32}>
        {isLoadingAeropayLinkedBankAccounts ? <Skeleton height={110} /> : null}
        {isAeropayEnabled && bankAccounts?.length > 0 ? (
          <Stack spacing={16} align="center">
            <Title variant="subhead-medium">{t('deposits.savedDepositMethods')}</Title>
            <Stack spacing={8} w="100%">
              {bankAccounts?.map((bankAccount, index) => (
                <PaymentTypeRadio
                  key={bankAccount.bankAccountId}
                  type={PaymentType.AEROPAY}
                  settings={{ isFree: true, isDisabled: false }}
                  isActive={bankAccountId === bankAccount.bankAccountId}
                  superscriptIndex={index + 1}
                  isWithdrawal={false}
                  onChange={() => {
                    onChange(PaymentType.AEROPAY, bankAccount.bankAccountId);
                  }}
                  bankAccount={bankAccount}
                  isDeleteBankAccountVisible
                />
              ))}
            </Stack>
          </Stack>
        ) : null}
        {isLoading ? <Skeleton height={310} /> : null}
        <Stack spacing={16} align="center">
          {isAeropayEnabled && bankAccounts?.length > 0 ? (
            <Title variant="subhead-medium">{t('deposits.otherDepositMethods')}</Title>
          ) : null}
          <SimpleGrid cols={2} spacing={8} w="100%">
            {data
              .filter(({ type }) => type !== PaymentType.AEROPAY || isAeropayEnabled) // Exclude Aeropay if not enabled
              .map(({ type, settings }, index) => (
                <PaymentTypeRadio
                  key={type}
                  type={type}
                  settings={settings}
                  isActive={type === paymentType && bankAccountId === undefined}
                  superscriptIndex={index + 1}
                  isWithdrawal={false}
                  onChange={() => {
                    if (!settings.isDisabled) {
                      onChange(type);
                    }
                  }}
                />
              ))}
          </SimpleGrid>
        </Stack>
      </Stack>
    );
  }

  return (
    <Input.Wrapper label={t('deposits.form.paymentType.label')}>
      <RadioButtonGroup>
        {isLoading && <Skeleton width="100%" height={75} />}
        {!isLoading &&
          filteredPaymentTypes.length > 0 &&
          filteredPaymentTypes
            // shouldn't be in filteredPaymentTypes on legacy for but if it is Exclude Aeropay if not enabled
            .filter((type) => type !== PaymentType.AEROPAY || isAeropayEnabled)
            .map((type) =>
              getIsPaymentTypeDisabled(type).isDisabled ? (
                <Tooltip
                  key={type}
                  label={t('deposits.tooltip.disabledPaymentMethod', {
                    paymentMethod: upperCaseToTitleCase(snakeCaseToTitleCase(type)),
                  })}
                  events={{ hover: true, focus: true, touch: true }}
                  zIndex={theme.other.zIndex.tooltip.default}
                >
                  <RadioButton
                    key={type}
                    $active={type === paymentType}
                    type="button"
                    data-test-id={dt.payments.deposits.depositForm.paymentTypeButton}
                  >
                    <Group spacing={8} noWrap>
                      <PaymentTypeIcon type={type} color={theme.colors.gray[6]} />
                      <Text color="gray.6" size={14}>
                        {t(`paymentTypes.${type}`)}
                      </Text>
                    </Group>
                  </RadioButton>
                </Tooltip>
              ) : (
                <RadioButton
                  key={type}
                  $active={type === paymentType}
                  onClick={() => onChange(type)}
                  type="button"
                  data-test-id={dt.payments.deposits.depositForm.paymentTypeButton}
                >
                  <Group spacing={8} noWrap>
                    <PaymentTypeIcon type={type} />
                    <Text size={14}>{t(`paymentTypes.${type}`)}</Text>
                  </Group>
                </RadioButton>
              )
            )}
      </RadioButtonGroup>
      {isCurrentPaymentTypeDisabled && (
        <ErrorAlert px="lg" mt="xs">
          {t('deposits.errors.unavailablePaymentType', {
            type: t(`paymentTypes.${paymentType}`),
          })}
        </ErrorAlert>
      )}
    </Input.Wrapper>
  );
}
