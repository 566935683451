import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';

import { logError } from '~/common/utils/log';
import { reportEvent } from '~/domains/analytics';
import { isSplashApiError } from '~/domains/services/utils/isBEApiError';
import { createAeropayDeposit } from '~/services/wallet/wallet.service.api';

import { useWalletBalance } from '../../hooks/useWalletBalance';
import { PaymentsQueryKeys } from '../../query';

import { isCustomDeposit } from './consts';

export function useCreateAeropayDeposit({
  setCompletedDepositAmount,
}: {
  setCompletedDepositAmount?: (amount: number) => void;
}) {
  const { t } = useTranslation('payments');
  const { refetch: refetchWalletBalance } = useWalletBalance();

  return useMutation({
    mutationKey: [PaymentsQueryKeys.CREATE_AEROPAY_DEPOSIT],
    mutationFn: async ({ amount, bankAccountId }: { amount: number; bankAccountId: string }) => {
      await createAeropayDeposit({
        amount,
        bankAccountId,
      });

      refetchWalletBalance();
      setCompletedDepositAmount?.(amount);
      reportEvent('Transactions > Deposit', {
        deposit_method: 'AEROPAY',
        deposit_amount: amount,
        is_custom_deposit: isCustomDeposit(amount),
        currency: 'USD',
      });
    },
    onError: (error) => {
      logError(error);
      showNotification({
        title: t('deposits.errors.aeropay.title'),
        message: isSplashApiError(error)
          ? error.response.data.message
          : t('deposits.errors.aeropay.general'),
        color: 'red',
        autoClose: 5000,
      });
      throw error;
    },
  });
}
