import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';

import type { getBankAccounts } from '~/services/wallet/wallet.service.api';
import { deleteAeropayAccount } from '~/services/wallet/wallet.service.api';
import { UserContext } from '~/components/providers/UserProvider';

import { PaymentsQueryKeys } from '../query';

import { useAeropayLinkedBankAccounts } from './useAeropayLinkedBankAccounts';

export function useDeleteAeropayAccount() {
  const { t } = useTranslation('payments');
  const { user } = useContext(UserContext);

  const { refetchBankAccounts } = useAeropayLinkedBankAccounts({ enabled: true });

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAeropayAccount,
    onSuccess: (_, { bankAccountId }) => {
      showNotification({
        message: t('deleteAeropayAccount.success.message'),
        color: 'green',
      });
      queryClient.setQueryData(
        PaymentsQueryKeys.GET_AEROPAY_LINKED_BANK_ACCOUNTS(user?.id),
        (oldData: Awaited<ReturnType<typeof getBankAccounts>>) => ({
          ...oldData,
          bankAccounts: oldData?.bankAccounts.filter(
            (account) => account.bankAccountId !== bankAccountId
          ),
        })
      );
      refetchBankAccounts();
    },
    onError: () => {
      showNotification({
        message: t('deleteAeropayAccount.error.message'),
        color: 'red',
      });
    },
  });
}
