import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { showNotification } from '@mantine/notifications';
import useTranslation from 'next-translate/useTranslation';

import { logError } from '~/common/utils/log';
import { aeropayChallenge, aeropayConfirmUser } from '~/services/wallet/wallet.service.api';
import { isSplashApiError } from '~/domains/services/utils/isBEApiError';
import { UserContext } from '~/components/providers/UserProvider';

import { PaymentsQueryKeys } from '../../query';

export function useAeropayChallenge() {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('payments');

  return useMutation({
    mutationKey: PaymentsQueryKeys.AEROPAY_CHALLENGE_REQUIRED(user?.id),
    mutationFn: async () => aeropayChallenge(),
    onError: (error) => {
      logError(error);
      showNotification({
        title: t('deposits.aeropay.challenge.error.title'),
        message: isSplashApiError(error)
          ? error.response.data.message
          : t('deposits.aeropay.challenge.error.message'),
        color: 'red',
        autoClose: 5000,
      });
      throw error;
    },
  });
}

export function useAeropayConfirmUser({ setIsDisabled, clearPin, setAeropayVerified }) {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('payments');

  return useMutation({
    mutationKey: PaymentsQueryKeys.AEROPAY_CONFIRM_USER(user?.id),
    mutationFn: async (code: string) => aeropayConfirmUser({ code }),
    onSuccess: () => {
      setAeropayVerified(true);
    },
    onError: (error) => {
      setAeropayVerified(false);
      showNotification({
        title: t('deposits.aeropay.challenge.error.title'),
        message: isSplashApiError(error)
          ? error.response.data.message
          : t('deposits.aeropay.challenge.error.message'),
        color: 'red',
        autoClose: 5000,
      });

      setIsDisabled(false);
      clearPin();

      throw error;
    },
  });
}
