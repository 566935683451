import { Text } from '~/domains/design-system/Text';
import { Title } from '~/domains/design-system/Title';
import { PrimaryWhale } from '~/components/shared/Logos/SplashLogo';
import type { titleStyles } from '~/domains/design-system/Title';
import type { textStyles } from '~/domains/design-system/Text';
import dt from '~/testing';

import useStyles from './SignUpHeader.styles';

type SignUpHeaderProps = {
  title: string;
  copy: React.ReactNode;
  logoSize?: number;
  centered?: boolean;
  titleVariant?: keyof typeof titleStyles;
  copyVariant?: keyof typeof textStyles;
  isLogoVisible?: boolean;
};

export function SignUpHeader({
  logoSize = 25,
  titleVariant = 'display-headline-expanded-xl',
  copyVariant = 'body-large',
  isLogoVisible = true,
  ...props
}: SignUpHeaderProps) {
  const { classes } = useStyles();
  return (
    <div
      className={props.centered ? classes.SignUpHeaderWrapperCentered : classes.SignUpHeaderWrapper}
    >
      {isLogoVisible && (
        <PrimaryWhale
          size={logoSize}
          className={props.centered ? classes.IconCentered : classes.Icon}
        />
      )}
      <Title
        data-test-id={dt.auth.components.signUpHeader.title}
        variant={titleVariant}
        c="gray2.6"
        className={classes.SignUpHeaderTitle}
      >
        {props.title}
      </Title>
      <Text variant={copyVariant} c="gray2.6">
        {props.copy}
      </Text>
    </div>
  );
}
