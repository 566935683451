import type { TablerIconsProps } from '@tabler/icons-react';

export function IconChase({ size = 32 }: TablerIconsProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#D1D1D1" />
      <g clipPath="url(#clip0_147_1785)">
        <path
          d="M13.1667 6.08331C12.75 6.08331 12.5 6.41665 12.5 6.74998V11.6666H25.5L19.5833 6.08331H13.1667Z"
          fill="#126BC5"
        />
        <path
          d="M25.9167 13.1667C25.9167 12.75 25.5834 12.5 25.25 12.5H20.3334V25.5L25.9167 19.5833V13.1667Z"
          fill="#126BC5"
        />
        <path
          d="M18.8334 25.9166C19.2501 25.9166 19.5001 25.5833 19.5001 25.25V20.3333H6.50006L12.4167 25.9166H18.8334Z"
          fill="#126BC5"
        />
        <path
          d="M6.08337 18.8333C6.08337 19.25 6.41671 19.5 6.75004 19.5H11.6667V6.58329L6.08337 12.5V18.8333Z"
          fill="#126BC5"
        />
      </g>
      <clipPath id="clip0_147_1785">
        <rect width="20" height="20" fill="white" transform="translate(6 6)" />
      </clipPath>
    </svg>
  );
}

IconChase.displayName = 'IconChase';
