import { DEFAULT_THEME, type MantineTheme } from '@mantine/core';
import { Inter } from 'next/font/google';
import localFont from 'next/font/local';

import { HEADER_HEIGHT } from '~/domains/common/components/AppShellHeader2/constants';

import { GRAY_COLORS, SPLASH_COLORS } from './const';
import { generateColors } from './themeUtils';

const inter = Inter({ subsets: ['latin'], fallback: ['sans-serif'] });
const hubotSans = localFont({
  src: '../../public/fonts/Hubot-Sans.woff2',
  variable: '--mantine-hubot-sans',
});
const hubotSansBlack = localFont({
  src: '../../public/fonts/Hubot-Sans-Black.ttf',
  variable: '--mantine-hubot-sans-black',
});
const hubotSansExpanded = localFont({
  src: '../../public/fonts/Hubot-Sans-Expanded-ExtraBold.ttf',
  variable: '--mantine-hubot-sans-expanded',
});
// For swatch generation, check out https://smart-swatch.netlify.app/

const baseTheme: MantineTheme = {
  ...DEFAULT_THEME,
  ...inter?.style,
  colorScheme: 'light',
  primaryColor: 'splash',
  primaryShade: { light: 4, dark: 3 },
  cursorType: 'pointer',
  // Default border-radius used for most elements
  defaultRadius: 4,
  // White and black colors, defaults to '#fff' and '#000'
  black: '#101113',
  white: '#fff',
  colors: {
    ...DEFAULT_THEME.colors,
    splash: SPLASH_COLORS,
    gray: GRAY_COLORS,
    // gray.1, gray.2, gray.3, gray.4, gray.5, gray.6, -, -, -
    gray2: [
      '#f1f3f5',
      '#d1d1d1',
      '#c1c2c5',
      '#909296',
      '#495057',
      '#25262b',
      '#1a1c1e',
      '#101113',
      '#101113',
      '#101113',
    ],
    red: generateColors(['#ED9D9D', '#DB3B3B', '#891C1C']),
    blue: generateColors(['#A5F5F1', '#4BEBE2', '#1BC4CF']),
    green: generateColors(['#B2F2BB', '#40C057', '#2B8A3E']),
    orange: generateColors(['#F5B094', '#EB612A', '#7C3518']),
    pink: generateColors(['#EDA3FB', '#DA47F7', '#7F1A93']),
    violet: generateColors(['#B4A6EF', '#6A4EDE', '#2D2057']),
  },
  other: {
    fontFamily: {
      hubotSans: `var(--mantine-hubot-sans)`,
      hubotSansBlack: `var(--mantine-hubot-sans-black)`,
      hubotSansExpanded: `var(--mantine-hubot-sans-expanded)`,
    },
    screenWidth: {
      lg: '1120px',
      xl: '1440px',
    },
    zIndex: {
      aeropayWidget: 9000,
      postPickModal: 1100,
      baseLayoutFooter: 999,
      deleteCardModal: 301,
      dialogModal: 201,
      header: 100,
      footer: 100,
      tooltip: {
        default: 99,
      },
      referAFriendDrawer: 100,
      headerDrawer: 90,
      dropdownFlyout: 80,
    },
    layout: {
      maxWidth: 1440,
      paddingLg: 24,
      paddingMd: 20,
      paddingSm: 10,
    },
    spacing: {
      _2: 2,
      _4: 4,
      _6: 6,
      _8: 8,
      _12: 12,
      _16: 16,
      _24: 24,
      _32: 32,
      _40: 40,
      _48: 48,
    },
  },

  breakpoints: {
    ...DEFAULT_THEME.breakpoints,
    xs: 480, // <-- "mobile"
  },

  globalStyles: (theme) => ({
    ':root': {
      // new AppShellHeader2 is not using Header from mantine, thus we need to set this variable manually, because there are many dependencies on it
      '--mantine-header-height': `${HEADER_HEIGHT}px`,
      '--mantine-hubot-sans': hubotSans.style.fontFamily,
      '--mantine-hubot-sans-black': hubotSansBlack.style.fontFamily,
      '--mantine-hubot-sans-expanded': hubotSansExpanded.style.fontFamily,
    },

    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },

    body: {
      ...theme.fn.fontStyles(),
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      lineHeight: theme.lineHeight,
    },

    // full height
    'html, body, #__next, #storybook-root': {
      height: '100%',
    },

    // Hide OSANO cookie widget button
    '.osano-cm-widget': {
      display: 'none !important',
    },

    ...(global.Cypress &&
      process.env.NEXT_PUBLIC_ENV !== 'production' && {
        '.osano-cm-dialog': {
          display: 'none',
        },
      }),

    // hide recaptcha badge by https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
    '.grecaptcha-badge': {
      visibility: 'hidden',
    },
  }),
};

export const mainTheme: MantineTheme = {
  ...baseTheme,
};

export const lightTheme: MantineTheme = {
  ...baseTheme,
  colorScheme: 'light',
};
