import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';

import { createBankAccountLink } from '~/services/wallet/wallet.service.api';

import { PaymentsQueryKeys } from '../../query';

export function useCreateBankAccountLink() {
  const { t } = useTranslation('payments');
  return useMutation({
    mutationKey: [PaymentsQueryKeys.CREATE_BANK_ACCOUNT_LINK],
    mutationFn: async ({ userId, userPassword }: { userId: string; userPassword: string }) =>
      createBankAccountLink({
        userId,
        userPassword,
      }),
    onSuccess: () => {
      showNotification({
        title: t('deposits.aeropay.bankAccountLinkCreated.title'),
        message: t('deposits.aeropay.bankAccountLinkCreated.message'),
      });
    },
  });
}
