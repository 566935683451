import { Flex, Paper, Skeleton, Stack, Title, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { IconInfoCircle } from '@tabler/icons-react';

import { formatDollars, formatDollarsSplitParts } from '~/domains/common/utils/formatters';
import { Tooltip } from '~/domains/common/components/Tooltip/Tooltip';
import dt from '~/testing';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';
import { Text } from '~/domains/common/components/Text/Text';

import { useWalletBalance } from '../hooks/useWalletBalance';

type BalanceBoxProps = {
  variant?: 'availableBalance' | 'withdrawableBalance';
};

function BalanceBox({ variant = 'withdrawableBalance' }: BalanceBoxProps) {
  const isNewWithdrawalEnabled = useIsFeatureEnabled(FEATURE.ENABLE_NEW_WITHDRAWAL_FLOW);

  const { t } = useTranslation('payments');
  const theme = useMantineTheme();
  const { data, isLoading } = useWalletBalance();

  const balance =
    variant === 'withdrawableBalance' ? data?.withdrawableBalance : data?.availableBalance;

  return (
    // zIndex fixes the Tooltip being cut off by the Skeleton
    <Skeleton visible={isLoading} sx={{ zIndex: 1 }}>
      <Paper
        bg={
          // eslint-disable-next-line no-nested-ternary
          isNewWithdrawalEnabled
            ? theme.white
            : theme.colorScheme === 'dark'
              ? theme.colors.dark[6]
              : theme.colors.gray[1]
        }
        px={isNewWithdrawalEnabled ? 18 : 'lg'}
        py={isNewWithdrawalEnabled ? 12 : 'lg'}
      >
        <Flex
          align="center"
          justify="space-between"
          direction={isNewWithdrawalEnabled ? 'row' : 'column-reverse'}
        >
          <Flex gap={4}>
            <Text
              size={isNewWithdrawalEnabled ? undefined : 16}
              color={
                // eslint-disable-next-line no-nested-ternary
                isNewWithdrawalEnabled
                  ? theme.colors.gray[9]
                  : theme.colorScheme === 'dark'
                    ? theme.colors.gray[4]
                    : theme.colors.gray[7]
              }
              variant={isNewWithdrawalEnabled ? 'web-subhead-medium' : undefined}
            >
              {variant === 'withdrawableBalance' ? t('withdrawableBalance') : t('currentBalance')}
            </Text>
            {variant === 'withdrawableBalance' ? (
              <Tooltip
                position="bottom"
                multiline
                label={
                  <Stack spacing={4} p={8} maw={265}>
                    <Title fz={14} lh="20px">
                      {t('withdrawableBalanceTooltipTitle')}
                    </Title>
                    <Text c="gray.9" fz={12} lh="16px" color="red">
                      {t('withdrawableBalanceTooltipText')}
                    </Text>
                  </Stack>
                }
              >
                <IconInfoCircle
                  color={theme.colors.gray[6]}
                  size={isNewWithdrawalEnabled ? 16 : undefined}
                />
              </Tooltip>
            ) : null}
          </Flex>
          {isNewWithdrawalEnabled ? (
            <Text
              c="gray.9"
              variant="web-headline-medium"
              data-test-id={dt.payments.components.withdrawableBalanceText}
            >
              {typeof balance === 'number' ? formatDollarsSplitParts(balance).formattedWhole : '-'}
              <Text component="sup" variant="web-subhead-small" top="-1em">
                {typeof balance === 'number'
                  ? formatDollarsSplitParts(balance).formattedDecimal
                  : '-'}
              </Text>
            </Text>
          ) : (
            <Text fw={700} size={32} c="splash.5" variant={undefined}>
              {typeof balance === 'number' ? formatDollars(balance, true) : '-'}
            </Text>
          )}
        </Flex>
      </Paper>
    </Skeleton>
  );
}

export { BalanceBox };
