export default {
  topSection: {
    withdrawalBalance: 'dt.account.header.withdrawalBalance',
  },
  transactionHistory: {
    accordion: 'dt.account.transactionHistory.accordion',
    noTransactionsLabel: 'dt.account.transactionHistory.noTransactionsLabel',
    balanceCell: 'dt.account.transactionHistory.balanceCell',
    transactionHistoryModalItem: 'dt.account.transactionHistory.transactionHistoryModalItem',
    recentTransactions: 'dt.account.transactionHistory.recentTransactions',
    pendingTransactions: 'dt.account.transactionHistory.pendingTransactions',
    tableRow: 'dt.account.transactionHistory.tableRow',
    amountCell: 'dt.account.transactionHistory.amountCell',
  },
};
