function IconChevronRight({
  color = '#1a1a1a',
  size = 16,
  strokeWidth = 1.6,
}: {
  color?: string;
  size?: number;
  strokeWidth?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m6 4 4 4-4 4"
      />
    </svg>
  );
}

IconChevronRight.displayName = 'IconChevronRight';

export { IconChevronRight };
