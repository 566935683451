import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  SignUpHeaderWrapper: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    maxWidth: 420,
    gap: 8,
  },
  SignUpHeaderWrapperCentered: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    alignSelf: 'stretch',
    minHeight: 126,
    maxWidth: 420,
    gap: 8,
    paddingLeft: 0,
    paddingRight: 0,
  },
  SignUpHeaderTitle: {
    textTransform: 'uppercase',
  },
  Icon: {
    marginBottom: 0,
  },
  IconCentered: {
    marginBottom: 20,
  },
}));

export default useStyles;
