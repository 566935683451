export default {
  page: {
    locationInfo: {
      container: 'dt.contestDetail.page.locationInfo.container',
      recheckLocationButton: 'dt.contestDetail.page.locationInfo.recheckLocationButton',
    },
    decription: 'dt.contestDetail.page.header.decription',
    header: {
      actions: {
        invite: 'dt.contestDetail.page.header.actions.invite',
        copy: 'dt.contestDetail.page.header.actions.copy',
        new: 'dt.contestDetail.page.header.actions.new',
        view: 'dt.contestDetail.page.header.actions.view',
        edit: 'dt.contestDetail.page.header.actions.edit',
      },
      closeButton: 'dt.contestDetail.page.header.closeButton',
      container: 'dt.contestDetail.page.header.container',
      content: 'dt.contestDetail.page.header.content',
      subtitle: 'dt.contestDetail.page.header.subtitle',
      title: 'dt.contestDetail.page.header.title',
      banner: 'dt.contestDetail.page.header.banner',
      thumbnail: 'dt.contestDetail.page.header.thumbnail',
    },
  },
  newEntryButton: 'dt.contestDetail.newEntryButton',
  section: {
    about: {
      baseballIcon: 'dt.contestDetail.section.about.baseballIcon',
      basketballIcon: 'dt.contestDetail.section.about.basketballIcon',
      contestType: 'dt.contestDetail.section.about.contestType',
      multiEntry: 'dt.contestDetail.section.about.multiEntry',
      creator: 'dt.contestDetail.section.about.creator',
      description: 'dt.contestDetail.section.about.description',
      rosterRequirements: 'dt.contestDetail.section.about.roster.requirements',
      footballIcon: 'dt.contestDetail.section.about.footballIcon',
      game: {
        item: {
          collapse: 'dt.contestDetail.section.about.game.item.collapse',
          container: 'dt.contestDetail.section.about.game.item.container',
          date: 'dt.contestDetail.section.about.game.item.date',
          name: 'dt.contestDetail.section.about.game.item.name',
          nogames: 'dt.contestDetail.section.about.game.item.nogames',
        },
        slate: {
          container: 'dt.contestDetail.section.about.game.slate.container',
          title: 'dt.contestDetail.section.about.game.slate.title',
        },
      },
      golfIcon: 'dt.contestDetail.section.about.golfIcon',
      hockeyIcon: 'dt.contestDetail.section.about.hockeyIcon',
      slate: 'dt.contestDetail.section.about.slate',
      soccerIcon: 'dt.contestDetail.section.about.soccerIcon',
      sport: 'dt.contestDetail.section.about.sport',
      title: 'dt.contestDetail.section.about.title',
      unknownIcon: 'dt.contestDetail.section.about.unknownIcon',
      pickDeadline: 'dt.contestDetail.section.about.pickDeadline',
      entryDeadline: 'dt.contestDetail.section.about.entryDeadline',
    },
    myEntries: {
      item: {
        container: 'dt.contestDetail.section.myEntries.item.container',
        image: 'dt.contestDetail.section.myEntries.item.image',
        text: 'dt.contestDetail.section.myEntries.item.text',
        teamAbbreviation: 'dt.contestDetail.section.myEntries.item.teamAbbreviation',
      },
      modal: 'dt.contestDetail.section.myEntries.modal',
      noEntries: 'dt.contestDetail.section.myEntries.noEntries',
      row: {
        container: 'dt.contestDetail.section.myEntries.row.container',
        options: {
          container: 'dt.contestDetail.section.myEntries.row.options.container',
          edit: {
            text: 'dt.contestDetail.section.myEntries.row.options.edit.text',
            link: 'dt.contestDetail.section.myEntries.row.options.edit.link',
          },
          withdrawal: {
            text: 'dt.contestDetail.section.myEntries.row.options.withdrawal.text',
          },
        },
        team: {
          button: 'dt.contestDetail.section.myEntries.row.team.button',
          bestPickIcon: 'dt.contestDetail.section.myEntries.row.team.bestPickIcon',
          abbreviationText: 'dt.contestDetail.section.myEntries.row.team.abbreviationText',
        },
        title: 'dt.contestDetail.section.myEntries.row.title',
        items: 'dt.contestDetail.section.myEntries.row.items',
      },
      skeleton: 'dt.contestDetail.section.myEntries.skeleton',
      title: 'dt.contestDetail.section.myEntries.title',
      viewAll: 'dt.contestDetail.section.myEntries.viewAll',
    },
    prizes: {
      row: 'dt.contestDetail.section.prizes.row',
      title: 'dt.contestDetail.section.prizes.title',
      resizableContest: 'dt.contestDetail.section.prizes.resizableContest',
      standardContest: 'dt.contestDetail.section.prizes.standardContest',
      guaranteedContest: 'dt.contestDetail.section.prizes.guaranteedContest',
    },
    rules: {
      row: 'dt.contestDetail.section.rules.row',
      title: 'dt.contestDetail.section.rules.title',
    },
  },
};
