import type { AxiosError } from 'axios';

import { isApiError } from './isApiError';

type SplashApiErrorResponse = {
  error: string;
  id: string;
  message: string;
  statusCode: number;
};

type SplashPropsApiErrorResponse = {
  message: string;
  status: 'error';
};

export const isSplashApiError = (
  error: unknown
): error is AxiosError<SplashApiErrorResponse | SplashPropsApiErrorResponse> => {
  if (!isApiError(error)) {
    return false;
  }

  const data = error.response?.data as SplashApiErrorResponse | SplashPropsApiErrorResponse;

  if (!data) {
    return false;
  }

  return (
    (!!(data as SplashApiErrorResponse).error &&
      !!(data as SplashApiErrorResponse).id &&
      !!(data as SplashApiErrorResponse).message &&
      !!(data as SplashApiErrorResponse).statusCode) ||
    // Props service
    ((data as SplashPropsApiErrorResponse).status === 'error' && !!data.message)
  );
};
