export default {
  dfs: {
    playerCard: {
      abbrItem: 'dt.contest.game.common.dfs.playerCard.abbrItem',
      container: 'dt.contest.game.common.dfs.playerCard.container',
      header: 'dt.contest.game.common.dfs.playerCard.header',
      info: 'dt.contest.game.common.dfs.playerCard.info',
      playerName: 'dt.contest.game.common.dfs.playerCard.playerName',
      remove: 'dt.contest.game.common.dfs.playerCard.remove',
    },
    pickBar: {
      clearButton: 'dt.contest.game.common.dfs.pickBar.clearButton',
      container: 'dt.contest.game.common.dfs.pickBar.container',
      createEntryButton: 'dt.contest.game.common.dfs.pickBar.createEntryButton',
      drafted: 'dt.contest.game.common.dfs.pickBar.drafted',
      loginButton: 'dt.contest.game.common.dfs.pickBar.loginButton',
      submitButton: 'dt.contest.game.common.dfs.pickBar.submitButton',
      tierButton: 'dt.contest.game.common.dfs.pickBar.tierButton',
      tierButtonIconCheck: 'dt.contest.game.common.dfs.pickBar.tierButtonIconCheck',
    },
    pickGrid: {
      container: 'dt.contest.game.common.dfs.pickGrid.container',
      subTitle: 'dt.contest.game.common.dfs.pickGrid.sub.title',
      title: 'dt.contest.game.common.dfs.pickGrid.title',
    },
    playerDetail: {
      container: 'dt.contest.game.common.dfs.playerDetail.container',
      season: {
        container: 'dt.contest.game.common.dfs.playerDetail.season.container',
        item: 'dt.contest.game.common.dfs.playerDetail.season.item',
        items: 'dt.contest.game.common.dfs.playerDetail.season.items',
      },
      action: 'dt.contest.game.common.dfs.playerDetail.action',
    },
  },
  pickEm: {
    pickEmPicksPanel: {
      clearButton: 'dt.contest.game.common.pickEm.pickEmPicksPanel.clearButton',
      collapseButton: 'dt.contest.game.common.pickEm.pickEmPicksPanel.collapseButton',
      container: 'dt.contest.game.common.pickEm.pickEmPicksPanel.container',
      item: {
        container: 'dt.contest.game.common.pickEm.pickEmPicksPanel.item.container',
        teamNameText: 'dt.contest.game.common.pickEm.pickEmPicksPanel.item.teamNameText',
        bestPickIcon: 'dt.contest.game.common.pickEm.pickEmPicksPanel.item.bestPickIcon',
        shuffleButton: 'dt.contest.game.common.pickEm.pickEmPicksPanel.item.shuffleButton',
      },
      missedPicksText: 'dt.contest.game.common.pickEm.pickEmPicksPanel.missedPicksText',
      picksMadeText: 'dt.contest.game.common.pickEm.pickEmPicksPanel.picksMadeText',
      submitButton: 'dt.contest.game.common.pickEm.pickEmPicksPanel.submitButton',
    },
  },
  picksheet: {
    games: {
      container: 'dt.contest.game.common.picksheet.games.container',
      teamName: 'dt.contest.game.common.picksheet.games.teamName',
      item: 'dt.contest.game.common.picksheet.games.item',
      upcoming: 'dt.contest.game.common.picksheet.games.upcoming',
      final: 'dt.contest.game.common.picksheet.games.final',
      live: 'dt.contest.game.common.picksheet.games.live',
      standalone: 'dt.contest.game.common.picksheet.games.standalone',
      tooltip: 'dt.contest.game.common.picksheet.games.tooltip',
      noteam: 'dt.contest.game.common.picksheet.games.noteam',
      seed: 'dt.contest.game.common.picksheet.games.seed',
      action: {
        preview: 'dt.contest.game.common.picksheet.action.preview',
      },
    },
    sort: 'dt.contest.game.common.picksheet.sort',
    sortWrapper: 'dt.contest.game.common.picksheet.wrapper',
    title: 'dt.contest.game.common.picksheet.title',
  },
  header: {
    closeButton: 'dt.contest.game.common.header.closeButton',
  },
  subHeader: {
    container: 'dt.contest.game.common.subheader.container',
    date: 'dt.contest.game.common.subheader.date',
    status: 'dt.contest.game.common.subheader.status',
  },
  footer: {
    container: 'dt.contest.game.common.footer.container',
    date: 'dt.contest.game.common.footer.date',
    picks: 'dt.contest.game.common.footer.picks',
  },
  aside: {
    closeButton: 'dt.contest.game.common.aside.closeButton',
    container: 'dt.contest.game.common.aside.container',
  },
  modal: {
    makePicksModal: {
      closeButton: 'dt.contest.game.common.modal.makePicksModal.closeButton',
      container: 'dt.contest.game.common.modal.makePicksModal.container',
      inviteButton: 'dt.contest.game.common.modal.makePicksModal.inviteButton',
      linkButton: 'dt.contest.game.common.modal.makePicksModal.linkButton',
      primaryButton: 'dt.contest.game.common.modal.makePicksModal.primaryButton',
    },
  },
};
