const enum TransactionType {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

// eslint-disable-next-line no-restricted-syntax
enum PaymentMethod {
  AEROPAY = 'AEROPAY',
  CARD = 'CARD',
  PAYPAL = 'PAYPAL',
  ONLINE_BANKING = 'ONLINE_BANKING',
  PAPER_CHECK = 'PAPER_CHECK',
  VENMO = 'VENMO',
  SKRILL = 'SKRILL',
}

// eslint-disable-next-line no-restricted-syntax
enum PaymentType {
  SKRILL = 'SKRILL',
  AEROPAY = 'AEROPAY',
  CARD = 'CARD',
  PAYPAL = 'PAYPAL',
  PAPER_CHECK = 'PAPER_CHECK',
  VENMO = 'VENMO',
  VIPPREFERRED = 'VIPPREFERRED',
}

// eslint-disable-next-line no-restricted-syntax
enum AeropayBankName {
  AEROSYNC = 'Aerosync Bank',
  AEROSYNC_MFA = 'Aerosync Bank (MFA)',
  AEROSYNC_MFA_TRIGGER = 'Aerosync Bank (MFA Trigger)',
  AEROSYNC_OAUTH = 'Aerosync Bank (oAuth)',
  BANKOFAMERICA = 'Bank of America',
  BANKOFAMERICA_OAUTH = 'Bank of America Oauth',
  CAPITALONE = 'Capital One',
  CAPITALONE_INVESTMENTS = 'Capital One Investments, LLC',
  CHARLESSCHWAB = 'Charles Schwab',
  CHASE = 'Chase',
  CITI = 'Citibank',
  USAA = 'USAA',
  WELLSFARGO = 'Wells Fargo',
}

// eslint-disable-next-line no-restricted-syntax
enum AeropayBankInstitution {
  AEROSYNC = 'AEROSYNC',
  AEROSYNC_MFA = 'AEROSYNC_MFA',
  AEROSYNC_MFA_TRIGGER = 'AEROSYNC_MFA_TRIGGER',
  AEROSYNC_OAUTH = 'AEROSYNC_OAUTH',
  BANKOFAMERICA = 'BANKOFAMERICA',
  BANKOFAMERICA_OAUTH = 'BANKOFAMERICA_OAUTH',
  CAPITALONE = 'CAPITALONE',
  CAPITALONE_INVESTMENTS = 'CAPITALONE_INVESTMENTS',
  CHARLESSCHWAB = 'CHARLESSCHWAB',
  CHASE = 'CHASE',
  CITI = 'CITI',
  USAA = 'USAA',
  WELLSFARGO = 'WELLSFARGO',
}

const AEROPAY_BANK_NAME_OPTION = {
  [AeropayBankName.AEROSYNC]: AeropayBankInstitution.AEROSYNC,
  [AeropayBankName.AEROSYNC_MFA]: AeropayBankInstitution.AEROSYNC,
  [AeropayBankName.AEROSYNC_MFA_TRIGGER]: AeropayBankInstitution.AEROSYNC,
  [AeropayBankName.AEROSYNC_OAUTH]: AeropayBankInstitution.AEROSYNC,
  [AeropayBankName.BANKOFAMERICA]: AeropayBankInstitution.BANKOFAMERICA,
  [AeropayBankName.BANKOFAMERICA_OAUTH]: AeropayBankInstitution.BANKOFAMERICA,
  [AeropayBankName.CAPITALONE]: AeropayBankInstitution.CAPITALONE,
  [AeropayBankName.CAPITALONE_INVESTMENTS]: AeropayBankInstitution.CAPITALONE,
  [AeropayBankName.CHARLESSCHWAB]: AeropayBankInstitution.CHARLESSCHWAB,
  [AeropayBankName.CHASE]: AeropayBankInstitution.CHASE,
  [AeropayBankName.CITI]: AeropayBankInstitution.CITI,
  [AeropayBankName.USAA]: AeropayBankInstitution.USAA,
  [AeropayBankName.WELLSFARGO]: AeropayBankInstitution.WELLSFARGO,
};

const PAYMENT_TYPE_TO_METHOD = {
  [PaymentType.AEROPAY]: PaymentMethod.AEROPAY,
  [PaymentType.SKRILL]: PaymentMethod.SKRILL,
  [PaymentType.CARD]: PaymentMethod.CARD,
  [PaymentType.PAYPAL]: PaymentMethod.PAYPAL,
  [PaymentType.VIPPREFERRED]: PaymentMethod.ONLINE_BANKING,
  [PaymentType.VENMO]: PaymentMethod.VENMO,
  [PaymentType.PAPER_CHECK]: PaymentMethod.PAPER_CHECK,
} as const;

const enum FeeType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export {
  FeeType,
  PAYMENT_TYPE_TO_METHOD,
  PaymentMethod,
  PaymentType,
  TransactionType,
  AeropayBankInstitution,
  AEROPAY_BANK_NAME_OPTION,
  AeropayBankName,
};
