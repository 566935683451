'use client';

import Braze from '~/domains/analytics/braze/braze.class';
import { isBrowserFn } from '~/utils/browser';

import Branch from './branchio/branch-io.singleton';
import { clearAnalyticsUser, reportEvent, setAnalyticsUser, updateAnalyticsUser } from './events';
import initGtag from './initGtag';
import { initSegment, stopSegment } from './initSegment';
import { initFullStory, stopFullStory } from './fullStory/initFullStory';
import { FS_VISIBILITY, useFullStoryRef } from './fullStory/useFullStoryRef';
import { initBugSnag, stopBugSnag } from './initBugSnag';
import useReportLocationChange from './useReportLocationChange';
import { getAnonymousId, getStoredUser, setFirstTouchUTM } from './utils';

const initAnalytics = () => {
  if (isBrowserFn()) {
    // Parse first touch UTM parameters on load
    setFirstTouchUTM();
    const anonymousId = getAnonymousId();

    initBugSnag();
    initFullStory();
    initSegment(anonymousId);
    initGtag();
    Braze.start();
    Branch.start();
  }
};

const stopAnalytics = () => {
  if (isBrowserFn()) {
    stopBugSnag();
    stopFullStory();
    stopSegment();
    Braze.stop();
  }
};

export {
  FS_VISIBILITY,
  clearAnalyticsUser,
  reportEvent,
  setAnalyticsUser,
  updateAnalyticsUser,
  getAnonymousId,
  getStoredUser,
  initAnalytics,
  isBrowserFn,
  stopAnalytics,
  useFullStoryRef,
  useReportLocationChange,
};
