export function IconBankOfAmerica({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#D1D1D1" />
      <g clipPath="url(#clip0_147_1704)">
        <path
          d="M18.3369 22.9475C18.3369 22.6107 18.6106 22.337 18.9685 22.337C19.3264 22.337 19.6001 22.6107 19.6001 22.9475C19.6001 23.2844 19.3264 23.5791 18.9685 23.5791C18.6106 23.5791 18.3369 23.3054 18.3369 22.9475ZM18.4632 22.9475C18.4632 23.2212 18.6948 23.4528 18.9895 23.4528C19.2632 23.4528 19.4948 23.2423 19.4948 22.9475C19.4948 22.6739 19.2632 22.4633 18.9895 22.4633C18.6738 22.4633 18.4632 22.6949 18.4632 22.9475Z"
          fill="#E31837"
        />
        <path
          d="M19.2842 23.3053H19.1579L18.9474 22.9895H18.8211V23.3053H18.7369V22.6106H19.0527C19.1158 22.6106 19.179 22.6106 19.2211 22.6527C19.2842 22.6737 19.3053 22.7369 19.3053 22.8C19.3053 22.9263 19.2 22.9895 19.0737 22.9895L19.2842 23.3053ZM18.9474 22.9053C19.0527 22.9053 19.179 22.9263 19.179 22.8C19.179 22.7158 19.0948 22.6948 19.0106 22.6948H18.8211V22.9053H18.9474Z"
          fill="#E31837"
        />
        <path
          d="M15.9579 23.579C20.379 19.7896 26.5895 16.1054 30 14.779C29.4737 14.4422 28.6527 13.958 27.7264 13.4317C23.3264 14.8843 18.1895 18.1054 13.6 21.7475C14.379 22.3369 15.2 22.9475 15.9579 23.579Z"
          fill="#E31837"
        />
        <path
          d="M13.9367 13.2845C13.2841 12.9687 12.5683 12.674 12.0209 12.4213C10.3578 13.2424 8.18936 14.4845 5.45251 16.4424C6.04199 16.7582 6.67357 17.1371 7.34725 17.5161C9.45251 15.9582 11.5788 14.4845 13.9367 13.2845Z"
          fill="#012169"
        />
        <path
          d="M17.8737 11.4737C17.0737 11.0316 14.2948 9.99999 12.379 9.55788C11.8106 9.76841 11.0106 10.0842 10.4632 10.3158C11.1579 10.5053 13.7474 11.2 15.9579 12.3158C16.5264 12.0421 17.3264 11.7053 17.8737 11.4737Z"
          fill="#E31837"
        />
        <path
          d="M8.46316 11.2003C6 12.3371 3.41053 13.895 2 14.8003C2.50526 15.0318 3.01053 15.2213 3.70526 15.5582C6.82105 13.4529 9.26316 12.1687 10.2316 11.7476C9.53684 11.495 8.88421 11.3266 8.46316 11.2003Z"
          fill="#012169"
        />
        <path
          d="M19.7265 10.8212C20.2949 10.6317 20.9475 10.4633 21.516 10.2949C19.8739 9.60014 17.8107 8.8633 15.9581 8.40014C15.6633 8.48435 14.7791 8.71593 14.1686 8.9054C14.8002 9.09488 16.8844 9.57909 19.7265 10.8212ZM9.34753 18.737C10.0212 19.137 10.737 19.6843 11.4317 20.1475C16.0423 16.5686 20.5896 13.8107 25.5791 12.2528C24.8844 11.8949 24.2739 11.5791 23.4949 11.2001C20.5054 11.958 15.3896 14.0001 9.34753 18.737Z"
          fill="#E31837"
        />
      </g>
      <defs>
        <clipPath id="clip0_147_1704">
          <rect width="28" height="15.1789" fill="white" transform="translate(2 8.4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
IconBankOfAmerica.displayName = 'IconBankOfAmerica';
